import React from "react";
import { Icon, Flex } from "@chakra-ui/react";
import { FiUsers, FiUser, FiHome, FiAlertCircle, FiAirplay, FiUserPlus, FiShield } from "react-icons/fi";
import {
  FaHistory, FaCog, FaPiggyBank, FaGavel, FaAlert, FaUserPlus, FaUserShield, FaKey, FaBriefcase, FaCertificate, FaSchool, FaCogs, FaPlus,
  FaUpload
} from "react-icons/fa";

import Dashboard from "views/admin/dashboard";
import Organization from "views/admin/organizationusers";

import CertificatesLayout from "views/admin/certificate/layout";
import CertificateDetails from "views/admin/certificate/details";
import Certificates from "views/admin/certificate";
import UploadCertificates from "views/admin/certificate/upload";


import AccountLayout from "views/admin/user/layout";
import Details from "views/admin/user/details";
import Users from "views/admin/user/users";
import AddUser from "views/admin/user/adduser";

import OrganizationLayout from "views/admin/organization/layout";
//import OrganizationDetails from "views/admin/organization/details";
import Organizations from "views/admin/organization/organizations";
import AddOrganization from "views/admin/organization/addorganization";
import OrganizationProfile from "views/admin/settings/admingroup";

import SettingsLayout from "views/admin/settings/layout";
import Profile from "views/admin/settings/index";
import Roles from "views/admin/settings/roles";
import AddRole from "views/admin/settings/addrole";
import ChangePassword from "views/admin/settings/changepassword";
import Configuration from "views/admin/settings/configuration";

import InstitutionLayout from "views/admin/institution/layout";
import Institutions from "views/admin/institution/institutions";
import AddInstitution from "views/admin/institution/addinstitution";
import InsitutionProfile from "views/admin/settings/institution";

import ReportLayout from "views/admin/report/layout";
import Transactions from "views/admin/report/transactions";
import Collections from "views/admin/report/collections";

const routes = [
  {
    name: "DASHBOARD",
    nameAbbrv: "Dashboard",
    layout: "/admin",
    path: "dashboard",
    icon: <Icon as={FiHome} width="20px" height="20px" />,
    component: <Dashboard />,
    collapse: false,
    show: true,
    allow: ["OrganizationAdmin", "Admin", "Super", "Partner", "Institution", "Organization"],
  },
  {
    name: "RECORDS",
    nameAbbrv: "Records",
    layout: "/admin",
    path: "certificates",
    icon: <Icon as={FaCertificate} width="20px" height="20px" />,
    component: <CertificatesLayout />,
    collapse: true,
    show: true,
    allow: ["Admin", "Manage Certificates"],
    items: [
      {
        name: "RECORDS",
        layout: "/admin",
        path: "certificates",
        icon: (
          <Icon as={FaCertificate} width="20px" height="20px" />
        ),
        component: <Certificates />,
        collapse: false,
        show: true,
        allow: ["Admin", "Manage Certificates"],
      },
      {
        name: "UPLOAD",
        layout: "/admin",
        path: "uploadcertificates",
        icon: (
          <Icon as={FaUpload} width="20px" height="20px" />
        ),
        component: <UploadCertificates />,
        collapse: false,
        show: true,
        allow: ["Admin", "Manage Certificates"],
      },
    ]
  },
  {
    name: "PARTNERS",
    nameAbbrv: "Organizations",
    layout: "/admin",
    path: "organizations",
    icon: <Icon as={FaBriefcase} width="20px" height="20px" />,
    component: <OrganizationLayout />,
    collapse: true,
    show: true,
    allow: ["Admin", "Manage Organizations"],
    items: [
      {
        name: "PARTNERS",
        layout: "/admin",
        path: "organizations",
        icon: (
          <Icon as={FaBriefcase} width="20px" height="20px" />
        ),
        component: <Organizations />,
        collapse: false,
        show: true,
        allow: ["Admin", "Manage Organizations"],
      },
      {
        name: "ADD PARTNER",
        layout: "/admin",
        path: "addorganization",
        icon: (
          <Icon as={FaPlus} width="20px" height="20px" />
        ),
        component: <AddOrganization />,
        collapse: false,
        show: true,
        allow: ["Admin", "Manage Organizations"],
      },
      {
        name: "ADD PARTNER",
        layout: "/admin",
        path: "addorganization/:organizationId",
        icon: (
          <Icon as={FaBriefcase} width="20px" height="20px" />
        ),
        component: <AddOrganization />,
        collapse: false,
        show: false,
        allow: ["Admin", "Manage Organizations"],
      },
    ]
  },
  {
    name: "INSTITUTIONS",
    nameAbbrv: "Institutions",
    layout: "/admin",
    path: "institutions",
    icon: <Icon as={FaSchool} width="20px" height="20px" />,
    component: <InstitutionLayout />,
    collapse: true,
    show: true,
    allow: ["Admin", "Manage Institutions"],
    items: [
      {
        name: "INSTITUTIONS",
        layout: "/admin",
        path: "institutions",
        icon: (
          <Icon as={FaSchool} width="20px" height="20px" />
        ),
        component: <Institutions />,
        collapse: false,
        show: true,
        allow: ["Admin", "Manage Institutions"],
      },
      {
        name: "ADD INSTITUTION",
        layout: "/admin",
        path: "addinstitution",
        icon: (
          <Icon as={FaPlus} width="20px" height="20px" />
        ),
        component: <AddInstitution />,
        collapse: false,
        show: true,
        allow: ["Admin", "Manage Institutions"],
      },
      {
        name: "ADD INSTITUTION",
        layout: "/admin",
        path: "addinstitution/:institutionId",
        icon: (
          <Icon as={FaSchool} width="20px" height="20px" />
        ),
        component: <AddInstitution />,
        collapse: false,
        show: false,
        allow: ["Admin", "Manage Institutions"],
      },
    ]
  },
  {
    name: "ORGANIZATIONS",
    nameAbbrv: "Organizations",
    layout: "/admin",
    path: "organizationsusers",
    icon: <Icon as={FiUser} width="20px" height="20px" />,
    component: <Organization />,
    collapse: false,
    show: true,
    allow: ["Admin", "Super"],
  },
  {
    name: "REPORT",
    nameAbbrv: "Report",
    layout: "/admin",
    path: "reports",
    icon: <Icon as={FaBriefcase} width="20px" height="20px" />,
    component: <ReportLayout />,
    collapse: true,
    show: true,
    allow: ["Admin", "OrganizationAdmin", "View Reports"],
    items: [
      {
        name: "TRANSACTIONS",
        layout: "/admin",
        path: "transactions",
        icon: (
          <Icon as={FaHistory} width="20px" height="20px" />
        ),
        component: <Transactions />,
        collapse: false,
        show: true,
        allow: ["Admin", "OrganizationAdmin", "View Reports"],
      },
      {
        name: "COLLECTIONS",
        layout: "/admin",
        path: "collections",
        icon: (
          <Icon as={FaPiggyBank} width="20px" height="20px" />
        ),
        component: <Collections />,
        collapse: false,
        show: true,
        allow: ["Admin", "OrganizationAdmin", "View Reports"],
      }
    ]
  },
  {
    name: "ADMINS",
    nameAbbrv: "Admins",
    layout: "/admin",
    path: "users",
    icon: <Icon as={FiUsers} width="20px" height="20px" />,
    component: <AccountLayout />,
    collapse: true,
    show: true,
    allow: ["Admin", "OrganizationAdmin", "Manage Users"],
    items: [
      {
        name: "USERS",
        layout: "/admin",
        path: "adminusers",
        icon: (
          <Icon as={FiUsers} width="20px" height="20px" />
        ),
        component: <Users />,
        collapse: false,
        show: true,
        allow: ["Admin", "OrganizationAdmin", "Manage Users"],
      },
      {
        name: "ADD USER",
        layout: "/admin",
        path: "adduser",
        icon: (
          <Icon as={FaPlus} width="20px" height="20px" />
        ),
        component: <AddUser />,
        collapse: false,
        show: true,
        allow: ["Admin", "OrganizationAdmin", "Manage Users"],
      },
      {
        name: "ADD USER",
        layout: "/admin",
        path: "adduser/:userId",
        icon: (
          <Icon as={FiUserPlus} width="20px" height="20px" />
        ),
        component: <AddUser />,
        collapse: false,
        show: false,
        allow: ["Admin", "OrganizationAdmin", "Manage Users"],
      },
    ]
  },
  {
    name: "SETTINGS",
    nameAbbrv: "Settings",
    layout: "/admin",
    path: "settings",
    icon: <Icon as={FaCog} width="20px" height="20px" color="#000000" />,
    component: <SettingsLayout />,
    collapse: true,
    show: true,
    allow: ["Admin", "OrganizationAdmin", "Partner", "Institution", "Organization", "all"],
    items: [
      {
        name: "USER ROLES",
        layout: "/admin",
        path: "roles",
        icon: (
          <Icon as={FaUserShield} width="20px" height="20px" color="white" />
        ),
        component: <Roles />,
        collapse: false,
        show: true,
        allow: ["Admin", "OrganizationAdmin", "Manage Roles"],
      },
      {
        name: "ADD ROLE",
        layout: "/admin",
        path: "addrole",
        icon: (
          <Icon as={FaUserShield} width="20px" height="20px" color="white" />
        ),
        component: <AddRole />,
        collapse: false,
        show: false,
        allow: ["Admin", "OrganizationAdmin", "Manage Roles"],
      },
      {
        name: "ADD ROLE",
        layout: "/admin",
        path: "addrole/:roleId",
        icon: (
          <Icon as={FaUserShield} width="20px" height="20px" color="white" />
        ),
        component: <AddRole />,
        collapse: false,
        show: false,
        allow: ["Admin", "OrganizationAdmin", "Manage Roles"],
      },
      {
        name: "PROFILE",
        layout: "/admin",
        path: "index",
        icon: (
          <Icon as={FiUser} width="20px" height="20px" color="white" />
        ),
        component: <Profile />,
        collapse: false,
        show: true,
        allow: ["all"],
      },
      {
        name: "ORGANIZATION",
        layout: "/admin",
        path: "organization",
        icon: (<Icon as={FaBriefcase} width="20px" height="20px" color="white" />),
        component: <OrganizationProfile />,
        collapse: false,
        show: true,
        allow: ["Partner"],
      },
      {
        name: "INSTITUTION",
        layout: "/admin",
        path: "institution",
        icon: (<Icon as={FaSchool} width="20px" height="20px" color="white" />),
        component: <InsitutionProfile />,
        collapse: false,
        show: true,
        allow: ["Institution"],
      },
      {
        name: "CHANGE PASSWORD",
        layout: "/admin",
        path: "changepassword",
        icon: (
          <Icon as={FaKey} width="20px" height="20px" color="white" />
        ),
        component: <ChangePassword />,
        collapse: false,
        show: true,
        allow: ["all"],
      },
      {
        name: "CONFIGURATION",
        layout: "/admin",
        path: "configuration",
        icon: (
          <Icon as={FaCogs} width="20px" height="20px" color="white" />
        ),
        component: <Configuration />,
        collapse: false,
        show: true,
        allow: ["Super"],
      },
    ],
  },
];

export default routes;