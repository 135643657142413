import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Flex, Heading, Text, Spinner, VStack, Badge, SimpleGrid, Stack, Icon, useToast } from "@chakra-ui/react";
import { GetAvailableRecords } from "services/certificateService";
import { FaInfo, FaLocationArrow } from "react-icons/fa";

export default function Organization() {
  const navigate = useNavigate();
  const toast = useToast();
  const [institutions, setInstitutions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const getAvailableRecords = async () => {
    try {
      setLoading(true);
      const response = await GetAvailableRecords();
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setInstitutions(response.data);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getAvailableRecords();
  }, []);
  if (loading) {
    return (
      <Flex justify="center" align="center" minH="100vh">
        <Spinner size="xl" thickness="4px" color="blue.500" />
      </Flex>
    );
  }

  if (error) {
    return (
      <Flex justify="center" align="center" minH="100vh">
        <Text color="red.500" fontSize="lg">{error}</Text>
      </Flex>
    );
  }

  return (
    <Box mt={{ sm: "40px", md: "80px" }} width="100%" mb="70">

      <Box mb="5">
        {" "}
        <Text
          color={"black"}
          mb="4px"
          fontFamily={"ManropeSemiBold"}
          fontSize={{ base: "md", sm: "lg", md: "xl", lg: "2xl" }}
        > Available Academic Record Data
        </Text>
      </Box>

      <Box bg="white" boxShadow="md" borderRadius="md" p={6} mb={8}>
        <Heading size={{ sm: "sm", md: "md" }} color="brand.500" mb={4}>
          Important Information
        </Heading>
        <Text color="gray.600" fontSize={{ sm: "sm", md: "md" }}>
          <Icon as={FaInfo} width="15px" height="15px" /> All Academic Record Data available for verification on Akowe are currently aggregated from Public Academic Records. As more Academic Record Data become available, this Status Page will be updated.
        </Text>
        <Text color="gray.600" fontSize={{ sm: "sm", md: "md" }} mt={2}>
          <Icon as={FaInfo} width="15px" height="15px" /> Efforts are currently ongoing to integrate directly with Tertiary Institutions to expand the available Academic Record Data and expand the available services.
        </Text>
        <Text color="gray.600" fontSize={{ sm: "sm", md: "md" }} mt={2}>
          <Icon as={FaInfo} width="15px" height="15px" />All searches that return results are charged at N3,000
        </Text>
        <Text color="gray.600" fontSize={{ sm: "sm", md: "md" }} mt={2}>
          <Icon as={FaInfo} width="15px" height="15px" /> All Verification Reports generated and downloaded are charged at N17,000
        </Text>
      </Box>

      <SimpleGrid columns={{ sm: 1, md: 3 }} spacing={8}>
        {institutions.map((institution) => (
          <Box
            key={institution._id}
            bg="white"
            boxShadow="lg"
            borderRadius="md"
            overflow="hidden"
            _hover={{ transform: "scale(1.05)", transition: "0.3s" }}
          >
            <VStack align="start" p={6}>
              <Heading size={{ sm: "sm", md: "md" }} color="brand.700">
                {institution.institution.institutionName}
              </Heading>

              <Flex>
                <Icon as={FaLocationArrow} width="18px" height="18px" color="brand.500" />
                <Text color="gray.500" fontSize={{ sm: "sm", md: "md" }} mt="-1">
                  &nbsp; {institution.institution.state}
                </Text>
              </Flex>
              {/*<Text color="gray.500" fontSize={{ sm: "sm", md: "md" }}>
                <b>Site URL:</b> {institution.institution.siteUrl}
              </Text>*/}
              <Stack direction="row" wrap="wrap" mt={4}>
                {institution.years.map((year) => (
                  <Badge key={year} colorScheme="teal" fontSize="sm" mx={1}>
                    {year}
                  </Badge>
                ))}
              </Stack>
            </VStack>
          </Box>
        ))}
      </SimpleGrid>


    </Box>
  );
};
