import { Document, Page, Text, View, StyleSheet, Image, pdf } from '@react-pdf/renderer';
import React from 'react';
import { fromByteArray } from "base64-js"
import TextLogo from "assets/images/textlogo.png";
import Logo from "assets/images/logo.svg";

export async function generateDownloadLink(generatedPDF, candidate) {
    try {
        const blob = await pdf(generatedPDF).toBlob();
        const blobURL = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobURL;
        link.download = candidate + 'full-report.pdf';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return true;
    } catch (error) {
        console.log(error)
        return false
    }
}


const bufferToBase64 = (buffer) => {
    // const binary = String.fromCharCode(...buffer.data);
    const binary = fromByteArray(buffer);
    return `data:image/png;base64,${btoa(binary)}`;

};


function arrayBufferToBase64(arrayBuffer) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(new
            Blob([arrayBuffer]));
    });
}


// highlight and yearbook are of the structure {type:"Buffer",data:[Uint8Array]}

export function DownloadablePDF({ certificate, highlightedPage, yearBook }) {

    if (!certificate) return;

    const yeaBookImage = yearBook && yearBook.data ? arrayBufferToBase64(new Uint8Array(yearBook.data)) : null;

    const highlightPageImage = highlightedPage && highlightedPage.data ? arrayBufferToBase64(new Uint8Array(highlightedPage.data)) : null;

    const styles = StyleSheet.create({
        page: {
            padding: 20,
            display: "flex",
            flexDirection: "column",
            gap: "20em",
            fontSize: "10px"
        },
        title1: {
            fontSize: "14px",
            fontWeight: 900
        },
        title2: {
            fontSize: "12px",
            fontWeight: 900
        },
        text: {
            fontSize: "10px"
        },
        logoSection: {
            display: "flex",
            justify: "space-between",
            height: "10vh"
        },
        table: { display: 'table', width: 'auto', borderStyle: 'solid', borderWidth: "1px", borderColor: '#000' },
        tableRow: { flexDirection: 'row' },
        tableColHeader: { width: '16.66%', borderStyle: 'solid', borderWidth: "1px", borderColor: '#000', backgroundColor: '#d3d3d3' },
        tableCol: { width: '16.66%', borderStyle: 'solid', borderWidth: "1px", borderColor: '#000' },
        tableCellHeader: { margin: 5, fontWeight: 'bold' },
        tableCell: { margin: 5 },
    })

    return (
        <>
            <Document>
                <Page size={'A4'} style={styles.page}>
                    {/* TODO: LOGO IMAGES */}
                    <View style={styles.logoSection}>
                        <View style={{ width: "100%", height: "100%" }}>
                            <Image src={TextLogo} style={{ width: "200px", height: "auto" }} />
                        </View>
                    </View>
                    <View>
                        <Text style={styles.title1}>
                            Akowe Verify
                        </Text>
                    </View>
                    <View>
                        <Text>
                            Instant Verification of Academic Records
                        </Text>
                    </View>
                    <View>
                        <Text style={styles.title2}>
                            Academic Records Verification Report
                        </Text>
                    </View>
                    <View>
                        <Text style={styles.title2}>
                            Credential Details:
                        </Text>
                    </View>
                    {/* First Table Table */}
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>Name</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>Institution</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>Course</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>Degree</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>Grade</Text>
                            </View>
                            <View style={styles.tableColHeader}>
                                <Text style={styles.tableCellHeader}>Year</Text>
                            </View>
                        </View>
                        <View style={styles.tableRow}>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{certificate?._doc?.name}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{certificate?.institution?.institutionName}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{certificate?._doc?.course}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{certificate?._doc?.degree}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{certificate?._doc?.grade}</Text>
                            </View>
                            <View style={styles.tableCol}>
                                <Text style={styles.tableCell}>{certificate?._doc?.year}</Text>
                            </View>
                        </View>
                    </View>
                    {/* Second Table */}
                    <View style={styles.table}>
                        <View style={styles.tableRow}>
                            <View style={{ ...styles.tableColHeader, width: "50%" }}>
                                <Text style={{ ...styles.title2, ...styles.tableCell }}>
                                    Verifiction Score*:
                                </Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: "50%" }}>
                                <Text style={{ ...styles.title2, ...styles.tableCell }}>
                                    30% Verfied
                                </Text>
                            </View>
                        </View>
                        <View style={{ ...styles.tableRow }}>
                            <View style={{ ...styles.tableColHeader, width: "50%" }}>
                                <Text style={{ ...styles.title2, ...styles.tableCell }}>
                                    Verification Method:
                                </Text>
                            </View>
                            <View style={{ ...styles.tableCol, width: "50%" }}>
                                <Text style={{ ...styles.title2, ...styles.tableCell }}>
                                    Public Records
                                </Text>
                            </View>
                        </View>
                    </View>

                    <View>
                        <Text style={styles.title1}>
                            Key:
                        </Text>
                    </View>

                    <View style={{ display: "flex", flexDirection: "column", gap: "20em" }}>
                        <Text style={styles.text}>
                            A verification score is assigned to a record based on the methods used in verifying such a
                            record. The Verification score is the sum of all the verification scores.

                        </Text>
                        <Text style={styles.text}>
                            Records verified from Public Academic Records are automatically assigned a 30% score because,
                            while published by the Tertiary Education Institutions, these records often contain errors such as
                            spelling errors and incomplete and unclear information.
                        </Text>
                        <Text style={styles.text}>
                            Records verified via our Peer-to-PeerTM system are also assigned a score. The maximum score
                            from the Peer-to-PeerTM system is 20%. The remaining 50% verification score is reserved for
                            direct institutional verification.
                        </Text>
                    </View>
                    <View>
                        <Text style={styles.title1}>
                            Disclaimer
                        </Text>
                    </View>
                    <View style={{ display: "flex", flexDirection: "column", gap: "20em", fontSize: "8px" }}>
                        <Text>
                            The information presented in this report is presented as is. The user of this information agrees to indemnify and
                            hold harmless Akowe for any decisions taken on use of the information presented in this report
                        </Text>
                        <Text>
                            Akowe shall not be responsible for any action taken by the user based on the data found on the site.
                        </Text>
                        <Text>
                            Akowe shall not be held liable by any educational institution for infringement of copyright or attendant laws by
                            aggregating and presenting the information on this site in the format used and, in the manner, presented.
                        </Text>
                    </View>
                    <View>
                        <Text style={{ fontSize: "8px", color: "#008081" }}>
                            Generated on Akowe Verify on {new Date().toLocaleString()}
                        </Text>
                    </View>
                </Page>

                {yeaBookImage &&
                    <Page>
                        <Image src={yeaBookImage} />
                    </Page>
                }
                {highlightPageImage &&
                    <Page>
                        <Image src={highlightPageImage} />
                    </Page>
                }
            </Document>
        </>
    )
}