import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Text,
  Tag,
  Button,
  Heading,
  Flex,
  VStack,
  Input,
  Stack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Icon,
  FormControl,
  FormLabel,
  Select,
  Skeleton,
  SkeletonText,
  useToast,
  useDisclosure
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { FaBook, FaSearch, FaUniversity, FaUser, FaCalendarDay } from "react-icons/fa";
import { GetInstitution } from "services/institutionService";
import { GetCountries } from "services/miscService";
import { FetchCertificate, GetSearchHistory, DownloadCertificate } from "services/certificateService";

export default function Index() {
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;

  const [searchData, setSearchData] = useState({
    candidateName: "",
    institution: "",
    country: ""
  });

  const [countries, setCountries] = useState([]);
  const [institutions, setIntitutions] = useState([]);
  const [records, setRecords] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(10);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [certificateId, setCertificateId] = useState("");
  const [historyLoading, setHistoryLoading] = useState(false);
  const [histories, setHistories] = useState(null);

  const [loading, setLoading] = useState(false);
  const getInstitutions = async () => {
    try {
      setLoading(true);
      const response = await GetInstitution(token);
      setLoading(false);
      if (response.success) {
        setIntitutions(response?.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getCountries = async () => {
    try {
      setLoading(true);
      const response = await GetCountries(token);
      setLoading(false);
      if (response.success) {
        setCountries(response?.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getSearchHistory = async () => {
    try {
      setHistoryLoading(true);
      const pagination = {
        from: from,
        limit: limit,
        orderBy: orderBy,
        sortOrder: sortOrder,
      };
      const filter = {
        deleted: false,
      };
      const response = await GetSearchHistory(pagination, filter, token);
      setHistoryLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setPageCount(response.data.count / limit);
        setHistories(response?.data?.searches);
      }
    } catch (error) {
      setHistoryLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const downloadCertificate = async () => {
    try {
      setDownloadLoading(true);
      const response = await DownloadCertificate(certificateId, token);
      setDownloadLoading(false);
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setDownloadLoading(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      /*if (!privileges.includes("Admin")) {
        toast({
          title: "You are not allowed",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }*/
      const response = await FetchCertificate(searchData, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setRecords(response.data);
        getSearchHistory();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  }

  useEffect(() => {
    /*if(!privileges.includes("Manage Certificates") && !privileges.includes("Admin")){
      toast({
        title: "You are not allowed",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      navigate("/admin/dashboard");
      return;
    }*/
    getInstitutions();
    getCountries();
    getSearchHistory();
  }, []);

  return (
    <>
      <Box mt={{ sm: "40px", md: "80px" }} width="100%" mb="70">

        <Stack
          direction={{ base: 'column', md: 'row' }} // column for sm screens, row for md+ screens
          spacing="10"
          mt="10"
        >
          <Box
            width={{ base: "100%", md: "30%" }} p="10" background="brand.100" borderRadius="10" minHeight={"600"}>
            <Heading size={{ sm: "sm", md: "md" }} color="#45005E">Quick Search</Heading>
            <Text mt="2" fontSize={{ sm: "md", md: "lg" }} color="#45005E">Fill/select a field or more to easily find verified credentials. If credential is not found, please connect with us to make a request.</Text>
            <VStack
              width="100%"
              spacing="6"
              mb="15"
              mt="5">
              <Box width="100%">
                <FormControl isRequired>
                  <FormLabel fontWeight="bold">Country</FormLabel>
                  <Select
                    bg="white"
                    variant="main"
                    placeholder="Select Country"
                    value={searchData.country}
                    onChange={(e) => {
                      setSearchData(prevSearchData => ({
                        ...prevSearchData,
                        country: e.target.value
                      }));
                    }}
                  >
                    {
                      countries.length > 0 &&
                      countries.map((country) => (
                        <option key={country._id} value={country._id}>
                          {country.countryName}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </Box>

              <Box width="100%">
                <FormControl isRequired>
                  <FormLabel fontWeight="bold">School/Institution</FormLabel>
                  <Select
                    bg="white"
                    variant="main"
                    placeholder="Select School/Instituition"
                    value={searchData.institution}
                    onChange={(e) => {
                      setSearchData(prevSearchData => ({
                        ...prevSearchData,
                        institution: e.target.value
                      }));
                    }}
                  >
                    {
                      institutions.length > 0 &&
                      institutions.map((institution) => (
                        <option key={institution._id} value={institution._id}>
                          {institution.institutionName}
                        </option>
                      ))}
                  </Select>
                </FormControl>
              </Box>

              <Box width="100%">
                <FormControl isRequired>
                  <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Candidate's Fullname</FormLabel>
                  <Input
                    bg="white"
                    variant="main"
                    maxLength={20}
                    placeholder="Candidate Name"
                    defaultValue={searchData.candidateName}
                    onChange={(e) => {
                      setSearchData(prevSearchData => ({
                        ...prevSearchData,
                        candidateName: e.target.value,
                      }));
                    }}
                  />
                </FormControl>
              </Box>
              <Box width="100%" mt="2">
                <Button variant={"brand"} onClick={handleSubmit} isLoading={historyLoading} loadingText={"...Wait"}> Search </Button>
              </Box>
            </VStack>
          </Box>

          <Box
            width={{ base: "100%", md: "70%" }} // 100% width on small screens, 70% on medium and up
            p="10" borderRadius="10" borderWidth={4} borderColor={"brand.200"} minHeight={"600"}>
            <Box width="100%"><Heading size={{ sm: "sm", md: "md" }} color="#45005E">Search Result</Heading></Box>
            <Box width="100%">
              <Accordion mt="4">
                {
                  !loading && records && records.map((record) => (
                    <AccordionItem p="2" bg="brand.100" borderWidth={0} borderRadius="15" mb="4">
                      <h2>
                        <AccordionButton>
                          <Box as='span' flex='1' textAlign='left' color="brand.500">
                            <Text fontSize={{ sm: "sm", md: "md" }} fontWeight="bold">{record.name}</Text>
                          </Box>
                          <AccordionIcon width="30px" height="30px" color="brand.500" />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Card background="brand.100" width="100%">
                          <VStack columns={{ sm: 2, md: 4 }} spacing="4" width="100%">
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}><Icon as={FaUser} width="18px" height="18px" />&nbsp; Candidate</Tag> <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark">{record.name}</Text></Flex></Box>
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}><Icon as={FaUniversity} width="18px" height="18px" />&nbsp; Institution</Tag><Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark">{record?.institutionName}</Text></Flex></Box>
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Faculty</Tag><Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark">{record?.faculty ? record?.faculty : "N/A"}</Text></Flex></Box>
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Course</Tag> <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark">{record.course ? record.course : "N/A"}</Text></Flex></Box>
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Degree</Tag>  <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark"> {record.degree ? record.degree : "N/A"}</Text></Flex></Box>
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Grade</Tag>  <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark"> {record.grade ? record.grade : "N/A"}</Text></Flex></Box>
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Year</Tag>  <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark"> {record.year ? record.year : "N/A"}</Text></Flex></Box>
                            {record.affliatedInstitution && <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Year</Tag>  <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark"> {record.affliatedInstitution ? record.affliatedInstitution : "N/A"}</Text></Flex></Box>}
                          </VStack>
                          <Box mt="4" textAlign={"right"}>
                            <Button
                              variant="primary"
                              isLoading={downloadLoading}
                              loadingText="..Wait"
                              size="sm"
                              onClick={(e) => {
                                setCertificateId(record._id);
                                downloadCertificate();
                              }}>Download Full Report</Button>
                          </Box>
                        </Card>
                      </AccordionPanel>
                    </AccordionItem>))}
              </Accordion>

              {
                loading && <LoadingHistory />
              }

              {
                !loading && !records &&
                <Box mt="20" width="100%" textAlign={"center"}>
                  <Heading size={{ sm: "sm", md: "md" }} color="gray.500">Enter credential information to view certificates.</Heading>
                  <Icon mt="4" as={FaSearch} width="40px" height="40px" color="gray.500" />
                </Box>
              }

              {records && records.length === 0 &&
                <Box mt="20" width="100%" textAlign={"center"}>
                  <Heading size={{ sm: "sm", md: "md" }} color="red.300">No record found on your searched candidate</Heading>
                  <Text fontSize={{ sm: "md", md: "lg" }} color="gray.500"> Try to conduct a new search.</Text>
                  <Icon mt="4" as={FaSearch} width="40px" height="40px" color="red.300" />
                </Box>
              }
            </Box>
          </Box>
        </Stack>
      </Box>
    </>
  );
}

const LoadingHistory = () => {
  return (
    <>
      <Card align="center" p="5px">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>

      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};