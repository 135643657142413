import React from "react";

// Chakra imports
import { Flex, Image, Heading, useColorModeValue } from "@chakra-ui/react";

import Logo from "assets/images/logo.svg";

export function SidebarBrand() {

  return (
    <Flex align='start' direction='column' p="0" pt="2" pb="2" mt="2">
      <Flex pl="5" gap="4"><Image src={Logo} width="40px" height="40px" alt="Akowe Verify" /><Heading color="brand.500" mt="3" fontSize={{ base: 'sm', sm: 'md', md: 'md', lg: 'lg' }}>Akowe Verify</Heading></Flex>
    </Flex>
  );
}

export default SidebarBrand;
