import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const UserLogin = async (user) => {
  try {
    const response = await axios.post(SERVER_URL + "/user/auth/login", user, {
      headers: {
        Authorization: `Bearer `,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UserForgotPassword = async (user) => {
  try {
    const response = await axios.post(SERVER_URL + "/user/auth/forgot", user, {
      headers: {
        Authorization: `Bearer `,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UserLoginWithOTP = async (user) => {
  try {
    const response = await axios.post(SERVER_URL + "/user/auth/loginwithotp", user, {
      headers: {
        Authorization: `Bearer `,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UpdatePassword = async (user) => {
  try {
    const response = await axios.post(SERVER_URL + "/user/updatePassword", user, {
      headers: {
        Authorization: `Bearer `,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UserRegister = async (user) => {
  try {
    const response = await axios.post(SERVER_URL + "/user/auth/register", user, {
      headers: {
        Authorization: `Bearer `,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UserVerify = async (codeData) => {
  try {
    const response = await axios.post(
      SERVER_URL + "/user/auth/verify",
      codeData,
      {
        headers: {
          Authorization: `Bearer `,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UserResendActivation = async (user) => {
  try {
    const response = await axios.post(
      SERVER_URL + "/user/auth/resend",
      user,
      {
        headers: {
          Authorization: `Bearer `,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UserResendOTP = async (user) => {
  try {
    const response = await axios.post(
      SERVER_URL + "/user/getotp",
      user,
      {
        headers: {
          Authorization: `Bearer `,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetUser = async (token) => {
  try {
    const response = await axios.get(SERVER_URL + "/user/me", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetUserByID = async (userId, token) => {
  try {
    const response = await axios.get(SERVER_URL + "/user/" + userId, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetUsers = async (pagination, filter, token) => {
  try {
    const response = await axios.post(
      SERVER_URL +
      "/user/getUsers?from=" +
      pagination.from +
      "&limit=" +
      pagination.limit +
      "&orderBy=" +
      pagination.orderBy +
      "&sortOrder=" +
      pagination.sortOrder,
      filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetAdminUsers = async (pagination, filter, token) => {
  try {
    const response = await axios.post(
      SERVER_URL +
      "/user/getAdminUsers?from=" +
      pagination.from +
      "&limit=" +
      pagination.limit +
      "&orderBy=" +
      pagination.orderBy +
      "&sortOrder=" +
      pagination.sortOrder,
      filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const CreateUser = async (userData, token) => {
  try {
    const response = await axios.post(SERVER_URL + "/user", userData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UpdateUser = async (userData, token) => {
  try {
    const response = await axios.patch(
      SERVER_URL + "/user",
      userData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UpdateUserByID = async (userData, userId, token) => {
  try {
    const response = await axios.patch(
      SERVER_URL + "/user/" + userId,
      userData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UploadUsers = async (userData, token) => {
  try {
    const response = await axios.post(
      SERVER_URL + "/user/uploadUsers",
      { users: userData },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const SearchUser = async (searchData, token) => {
  try {
    const response = await axios.post(SERVER_URL + "/user/search", searchData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetAllAdminUsers = async (token) => {
  try {
    const response = await axios.get(SERVER_URL + "/user/getAdminUsers", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetFilteredUsers = async (pagination, filter, token) => {
  try {
    const response = await axios.post(
      SERVER_URL +
      "/user/getfilteredusers?from=" +
      pagination.from +
      "&limit=" +
      pagination.limit +
      "&orderBy=" +
      pagination.orderBy +
      "&sortOrder=" +
      pagination.sortOrder,
      filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const DeleteUser = async (userId, token) => {
  try {
    const response = await axios.delete(SERVER_URL + "/user/" + userId, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export {
  UserLogin,
  UserRegister,
  UserResendActivation,
  UserVerify,
  UserLoginWithOTP,
  UserForgotPassword,
  UserResendOTP,
  UpdatePassword,
  GetUser,
  GetUserByID,
  GetUsers,
  GetAdminUsers,
  SearchUser,
  CreateUser,
  UpdateUser,
  UpdateUserByID,
  UploadUsers,
  GetAllAdminUsers,
  GetFilteredUsers,
  DeleteUser,
};
