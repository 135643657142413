import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box,
  Flex,
  VStack,
  Badge,
  SimpleGrid,
  Heading,
  Avatar,
  Center,
  Icon,
  Text,
  Tag,
  FormControl,
  FormLabel,
  Input,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  useToast,
  useDisclosure
} from "@chakra-ui/react";

import { GetOrganization, UpdateOrganizationByID } from "services/organizationService";
import { FiArrowLeft, FiUser, FiPhone, FiGlobe, FiHome } from "react-icons/fi";
import { FaEnvelope, FaExternalLinkAlt, FaMapMarkerAlt } from "react-icons/fa";

export default function Organization() {
  const navigate = useNavigate();
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const privileges = userState.hasOwnProperty("privileges")
    ? userState.privileges
    : [];

  const [loading, setLoading] = useState(false);
  const [organization, setOrganization] = useState(null);
  const [organizationData, setOrganizationData] = useState({
    organizationName: "",
    organizationAddress: "",
    email: "",
    phone: "",
    city: "",
    state: "",
    siteUrl: ""
  });

  const getOrganization = async () => {
    try {
      setLoading(true);
      const response = await GetOrganization(token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setOrganization(response.data);
        setOrganizationData(
          {
            organizationName: response.data.name,
            organizationAddress: response.data.address,
            email: response.data.email,
            phone: response.data.phone,
            city: response.data.city,
            state: response.data.state,
            siteUrl: response.data.siteUrl
          }
        );
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const handleUpdate = async () => {
    try {
      setLoading(true);
      const response = await UpdateOrganizationByID(organizationData, userState.organization, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      getOrganization();
      onDrawerClose();
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };


  useEffect(() => {
    //if (!privileges.includes("Admin")) {
    //navigate("/admin/dashboard");
    //return;
    // }
    getOrganization();
  }, []);

  return (
    <>
      <Drawer isOpen={isDrawerOpen} placement="right" onClose={onDrawerClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color="#45005E">Update Organization Info</DrawerHeader>
          <DrawerBody>

            <Box mt="5">
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Organization Name</FormLabel>
                <Input
                  variant="main"
                  maxLength={200}
                  placeholder="Organization Name"
                  readOnly={true}
                  disabled={true}
                  defaultValue={organizationData && organizationData.organizationName}
                  onChange={(e) => {
                    setOrganization(prevOrganization => ({
                      ...prevOrganization,
                      organizationName: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </Box>

            <Box mt="5">
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Organization Address</FormLabel>
                <Input
                  variant="main"
                  maxLength={200}
                  placeholder="Organization Address"
                  defaultValue={organizationData && organizationData.organizationAddress}
                  onChange={(e) => {
                    setOrganization(prevOrganization => ({
                      ...prevOrganization,
                      organizationAddress: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </Box>

            <Box mt="5">
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Site URL</FormLabel>
                <Input
                  variant="main"
                  maxLength={200}
                  placeholder="Site URL"
                  defaultValue={organizationData && organizationData.siteUrl}
                  onChange={(e) => {
                    setOrganization(prevOrganization => ({
                      ...prevOrganization,
                      siteUrl: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </Box>

            <Box mt="5">
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">City</FormLabel>
                <Input
                  variant="main"
                  maxLength={200}
                  type="text"
                  placeholder="Enter City"
                  defaultValue={organizationData && organizationData.city}
                  onChange={(e) => {
                    setOrganization(prevOrganization => ({
                      ...prevOrganization,
                      city: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </Box>

            <Box mt="5">
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">State</FormLabel>
                <Input
                  variant="main"
                  maxLength={200}
                  type="text"
                  placeholder="Enter State"
                  defaultValue={organizationData && organizationData.state}
                  onChange={(e) => {
                    setOrganization(prevOrganization => ({
                      ...prevOrganization,
                      state: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </Box>

            <Box mt="5">
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Email</FormLabel>
                <Input
                  variant="main"
                  maxLength={150}
                  placeholder="Enter Email"
                  defaultValue={organizationData && organizationData.email}
                  onChange={(e) => {
                    setOrganization(prevOrganization => ({
                      ...prevOrganization,
                      email: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </Box>

            <Box mt="5">
              <FormControl isRequired>
                <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Phone</FormLabel>
                <Input
                  variant="main"
                  maxLength={14}
                  placeholder="Enter Phone"
                  defaultValue={organizationData && organizationData.phone}
                  onChange={(e) => {
                    setOrganization(prevOrganization => ({
                      ...prevOrganization,
                      phone: e.target.value,
                    }));
                  }}
                />
              </FormControl>
            </Box>

            <Box textAlign="left" mt="5" width="100%">
              <Button
                variant="brand"
                width="150px"
                isLoading={loading}
                loadingText="Wait..."
                onClick={handleUpdate}
              >Update
              </Button>
            </Box>

          </DrawerBody>
        </DrawerContent>
      </Drawer>


      <Center width="100%">
        <Box mt={{ sm: "40px", md: "100px" }} width={{ sm: "90%", md: "50%" }} mb="20">
          {organization &&
            <>
              <Box width="100%" textAlign="center">
                <Avatar
                  size={{ sm: "md", md: "lg" }}
                  bg='teal.500'
                  name={organization && organization.organizationName}
                  icon={<Icon as={FiHome} width="20px" height="20px" mt="1" />}
                />
                <Heading mt="4" size={{ sm: "md", md: "lg" }} textAlign="center" color="#45005E"> {organization && organization.organizationName} </Heading>
              </Box>

              <SimpleGrid columns={{sm:1, md: 2}} width="100%" mt="5" background="#f2f9f9" p="5" borderRadius="10">
                <Box><Flex gap="3"><Icon as={FiPhone} width="18px" height="18px" mt="1" /> <Text color="#45005E" fontSize={{ sm: "md", md: "lg" }}>{organization.phone}</Text> </Flex></Box>
                <Box> <Flex gap="3"><Icon as={FaEnvelope} width="18px" height="18px" mt="1" /> <Text color="#45005E" fontSize={{ sm: "md", md: "lg" }}>{organization.email}</Text> </Flex></Box>
              </SimpleGrid>

              <VStack width="100%" spacing="6" mt="7" background="#f2f9f9" borderRadius="10" p="5" pb="8">
                <Box width="100%">
                  <Flex direction={{ sm: "column", md: "row" }} gap="3" align="start">
                    <Icon as={FiHome} width="18px" height="18px" mt="1" />
                    <Text fontFamily="ManropeRegular"
                      fontSize={{ sm: "md", md: "lg" }} color="#45005E">
                      {organization.name}
                    </Text>
                  </Flex>
                </Box>

                <Box width="100%">
                  <Flex direction={{ sm: "column", md: "row" }} gap="3" align="start">
                    <Icon as={FaMapMarkerAlt} width="18px" height="18px" mt="1" />
                    <Text fontFamily="ManropeRegular"
                      fontSize={{ sm: "md", md: "lg" }} color="#45005E">
                      {organization.organizationAddress} {organization.city && ", " + organization.city}  {organization.state && ", " + organization.state}  {organization.country && ", " + organization.country}
                    </Text>
                  </Flex>
                </Box>

                <Box width="100%">
                  <Flex direction={{ sm: "column", md: "row" }} gap="3" align="start">
                    <Icon as={FiGlobe} width="18px" height="18px" mt="1" />
                    <Text fontFamily="ManropeRegular"
                      fontSize={{ sm: "md", md: "lg" }} color="#45005E">
                      {organization.siteUrl}
                    </Text>
                  </Flex>
                </Box>

                <Box width="100%" mt="4">
                  <Flex direction={{ sm: "column", md: "row" }} gap="3" align="start">
                    <Badge fontFamily="ManropeRegular" variant="brand"
                      fontSize={{ base:"xs", sm: "sm", md: "lg" }}>
                      Test API Key
                    </Badge>
                    <Text fontFamily="ManropeRegular"
                      fontSize={{ base:"xs", md: "lg" }}>
                      {organization.testApiKey}
                    </Text>

                  </Flex>
                </Box>

                <Box width="100%">
                  <Flex direction={{ sm: "column", md: "row" }} gap="3" align="start">
                    <Badge fontFamily="ManropeRegular" variant="brand"
                      fontSize={{ base:"xs", sm: "sm", md: "lg" }}>
                      Live API Key
                    </Badge>
                    <Text fontFamily="ManropeRegular"
                      fontSize={{ base:"xs", md: "lg" }}>
                      {organization.productionApiKey}
                    </Text>

                  </Flex>
                </Box>

              </VStack>

              <Box width="100%" textAlign={"center"} mt="7">
                <Button
                  width={{ base:"150px", md: "200px" }}
                  fontSize={{ base:"sm", md: "lg" }}
                  variant="brand"
                  size="md"
                  onClick={onDrawerOpen}
                >
                  Edit Organization
                </Button>
              </Box>
            </>
          }
        </Box>
      </Center>
    </>
  );
}
