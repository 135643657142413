import axiosInstance from './axiosInstance'; // Adjust the path accordingly

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const GetStates = async (token) => {
  try {
    const response = await axiosInstance.get(SERVER_URL + "/state", {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
const GetCitys = async (token) => {
  try {
    const response = await axiosInstance.get(SERVER_URL + "/city", {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};


const GetLogs = async (pagination, filter, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL +
      "/log?from=" +
      pagination.from +
      "&limit=" +
      pagination.limit +
      "&orderBy=" +
      pagination.orderBy +
      "&sortOrder=" +
      pagination.sortOrder,
      filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};


const GetPlans = async (token) => {
  try {
    const response = await axiosInstance.get(SERVER_URL + "/plan", {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};


const CreateAttribute = async (data, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL + "/attribute",
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors"
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const EditAttribute = async (data, attributeId, token) => {
  try {
    const response = await axiosInstance.patch(
      SERVER_URL + "/attribute/" + attributeId,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors"
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetAttributes = async (token) => {
  try {
    const response = await axiosInstance.get(SERVER_URL + "/attribute", {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors"
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const DeleteAttribute = async (attributeId, token) => {
  try {
    const response = await axiosInstance.delete(SERVER_URL + "/attribute/" + attributeId, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetCountries = async (token) => {
  try {
    const response = await axiosInstance.get(SERVER_URL + "/country", {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetSkills = async (token) => {
  try {
    const response = await axiosInstance.get(SERVER_URL + "/skill", {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetCountry = async (countryId, token) => {
  try {
    const response = await axiosInstance.get(SERVER_URL + "/country/" + countryId, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetSettings = async (token) => {
  try {
    const response = await axiosInstance.get(SERVER_URL + "/settings/", {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const DeleteCountry = async (countryId, token) => {
  try {
    const response = await axiosInstance.delete(
      SERVER_URL + "/country/" + countryId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const CreateCountry = async (country, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/country", country, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UpdateCountry = async (countryData, countryId, token) => {
  try {
    const response = await axiosInstance.patch(
      SERVER_URL + "/country/" + countryId,
      countryData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UpdateSettings = async (settingsData, token) => {
  try {
    const response = await axiosInstance.patch(
      SERVER_URL + "/settings",
      settingsData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const SendSupport = async (supportData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/support", supportData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export {
  GetStates,
  GetCitys,
  GetCountries,
  GetSkills,
  GetCountry,
  DeleteCountry,
  CreateCountry,
  UpdateCountry,
  CreateAttribute,
  GetAttributes,
  DeleteAttribute,
  EditAttribute,
  UpdateSettings,
  GetSettings,
  GetPlans,
  SendSupport
};




