import { mode } from "@chakra-ui/theme-tools";
export const globalStyles = {
  colors: {
    brand: {
      100: "#f2f9f9",
      200: "#b3feff",
      300: "#80feff",
      400: "#4dfeff",
      500: "#008081",
      600: "#004c4d",
      700: "#007f80",
      800: "#00191a",
      900: "#008081",
    },
    secondary: {
      100: "#2095e5",
      200: "#2095e5",
      300: "#2095e5",
      400: "#2095e5",
      500: "#2095e5",
      600: "#2095e5",
      700: "#2095e5",
      800: "#2095e5",
      900: "#2095e5",
    },
    brandScheme: {
      100: "#5657c3",
      200: "#5657c3",
      300: "#3c3ea9",
      400: "#2f3084",
      500: "#292a73",
      600: "#11047A",
      700: "#22225e",
      800: "#141538",
      900: "#141538",
    },
    brandTabs: {
      100: "#5657c3",
      200: "#5657c3",
      300: "#3c3ea9",
      400: "#2f3084",
      500: "#292a73",
      600: "#11047A",
      700: "#22225e",
      800: "#141538",
      900: "#141538",
    },
    secondaryGray: {
      100: "#E0E5F2",
      200: "#E1E9F8",
      300: "#F4F7FE",
      400: "#E9EDF7",
      500: "#8F9BBA",
      600: "#A3AED0",
      700: "#707EAE",
      800: "#707EAE",
      900: "#1B2559",
    },
    red: {
      100: "#FEEFEE",
      500: "#EE5D50",
      600: "#E31A1A",
    },
    blue: {
      50: "#EFF4FB",
      100: "#8080ff",
      200: "#4d4dff",
      300: "#1a1aff",
      400: "#1a1aff",
      500: "#0000e6",
      600: "#0000b3",
      700: "#000080",
      800: "#00004d"
    },
    orange: {
      100: "#e79c64",
      200: "#e79c64",
      300: "#e79c64",
      400: "#e79c64",
      500: "#e28743",
      600: "#e08038",
      700: "#c7671f"
    },
    green: {
      100: "#E6FAF5",
      500: "#01B574",
    },
    navy: {
      50: "#d0dcfb",
      100: "#aac0fe",
      200: "#a3b9f8",
      300: "#728fea",
      400: "#3652ba",
      500: "#1b3bbb",
      600: "#24388a",
      700: "#1B254B",
      800: "#111c44",
      900: "#0b1437",
    },
    gray: {
      100: "#FAFCFE",
    },
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: "hidden",
        bg: mode("#ffffff", "#ffffff")(props),
        fontFamily: "ManropeRegular",
        letterSpacing: "-0.5px",
      },
      input: {
        color: "gray.700"
      },
      html: {
        fontFamily: "ManropeRegular",
      },
    }),
  },
};
