import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  Image,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  VStack,
  Center,
  useToast,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";
import DefaultAuth from "layouts/auth/Default";
import { UserLogin, UserResendActivation } from "services/userService";
import Card from "components/card/Card";
import { useSelector } from "react-redux";
import illustrationImage from "assets/images/login1.png";
import Logo from "assets/images/logo.svg";

function Index() {
  const currentUser = useSelector((state) => state?.user?.value);
  const isLoggedIn = currentUser.hasOwnProperty("token") ? true : false;

  const toast = useToast();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [showActivation, setShowActivation] = useState(false);

  const validationSchema = Yup.object({
    email: Yup.string().email().min(3).max(150).required().label("Email Address"),
    password: Yup
      .string()
      .min(6)
      .max(50)
      .matches(
        /^(?=.*[a-z])/,
        "Must contain at least one lowercase character"
      )
      .matches(
        /^(?=.*[A-Z])/,
        "Must contain at least one uppercase character"
      )
      .matches(/^(?=.*[0-9])/, "Must contain at least one number")
      .matches(
        /^(?=.*[!@#%&])/,
        "Must contain at least one special character ?=.*!@#%&"
      )
      .required()
      .label("Password")
  });

  const handleLogin = async (user, setSubmitting) => {
    try {
      if (!validateCaptcha(captchaToken)) {
        setCaptchaToken("");
        toast({
          title: "Invalid Captcha Entered",
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
        setSubmitting(false);
        return;
      }

      setSubmitting(true);
      const response = await UserLogin(user);
      setSubmitting(false);
      if (response.error || !response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        if (
          response.hasOwnProperty("data") &&
          response.data.hasOwnProperty("resendActivation") &&
          response.data.resendActivation
        ) {
          setEmail(response.data.email);
          setShowActivation(true);
        }
        return;
      }
      if (response.success) {
        //if (response.data.twoFaActivated) {
        navigate("/auth/twofactor/" + response.data._id);
        return;
        //}
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setSubmitting(false);
      return;
    }
  };

  const handleResendActivation = async () => {
    try {
      if (email === "") {
        toast({
          title: "We could not resend activation code, try again",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      const user = { email };
      setLoading(true);
      const response = await UserResendActivation(user);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setShowActivation(false);
        navigate("/auth/activate", { state: { email: email } });
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      currentUser && currentUser.accountType === "organization" ? navigate("/app/dashboard") : navigate("/admin/dashboard")
      return;
    }
    loadCaptchaEnginge(4, "#ffffff", "#004c4d", "upper");
  }, []);

  return (
    <>
      <Helmet>
        <title>Login | Login to your account</title>
        <meta name="description" content="Login to your account" />
        <meta name="keywords" content="" />
      </Helmet>

      <DefaultAuth illustrationImage={illustrationImage} heading="Verification of Academic Records">

        <Flex width={"100%"} justify={"center"}>
          <Stack
            spacing={4}
            mx={"auto"}
            mt={{ sm: "30px", md: "0px" }}
            w={{ base: "95%", sm: "90%", md: "60%", lg: "50%" }}
          >

            <Card
              width="100%"
              height="220px"
              background="brand.500"
              display={{ sm: "block", md: "none" }}
            >
              <Center width="100%" height="100%">
                <VStack
                  width="100%"
                  spacing="5"
                  mt="5"
                  mb="10"
                >
                  <Box width="100%">
                    <Heading
                      mt="5"
                      size={{ sm: "md", md: "xl" }}
                      textAlign={"center"}
                      color={"#ffffff"}
                      fontFamily="ManropeSemiBold"
                      fontWeight="bold"
                    >
                      Verification of Academic Records
                    </Heading>
                  </Box>
                  <Box display="flex"
                    alignItems="center"
                    justifyContent="center">
                    <Image src={illustrationImage} width="120px" height="auto" />
                  </Box>
                </VStack>
              </Center>
            </Card>

            <Stack p={2} align={"left"}>
              <Center width="100%"><Image src={Logo} width="70px" height="auto" /></Center>
              <Heading mt="2" fontFamily="ManropeSemiBold" fontWeight={"bold"} size={{ sm: "sm", md: "md" }} textAlign={"left"} color="#45005E">
                Sign In to your Account
              </Heading>
            </Stack>
            <Box p={2} width="100%">
              <Formik
                initialValues={{
                  email: "",
                  password: ""
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  handleLogin(values, setSubmitting);
                }}
              >
                {({ handleSubmit, handleChange, handleBlur, values, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>
                    <Stack spacing={4} width="100%">
                      <FormControl id="email" isRequired>
                        <FormLabel fontFamily="ManropeSemiBold">Email Address</FormLabel>
                        <Input
                          variant="main"
                          type="email"
                          name="email"
                          maxLength={100}
                          placeholder="Enter Email Address"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage name="email" component="div" style={{ color: 'red' }} />
                      </FormControl>

                      <FormControl id="password" isRequired>
                        <FormLabel fontFamily="ManropeSemiBold">Password</FormLabel>
                        <InputGroup>
                          <Input
                            type={showPassword ? "text" : "password"}
                            variant="main"
                            name="password"
                            maxLength={100}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Enter Password"
                          />
                          <InputRightElement h={"full"}>
                            <Button
                              variant={"ghost"}
                              onClick={() =>
                                setShowPassword((showPassword) => !showPassword)
                              }
                            >
                              {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                            </Button>
                          </InputRightElement>
                        </InputGroup>
                        <ErrorMessage name="password" component="div" style={{ color: 'red' }} />
                        <Text align={"right"}>
                          <Link color={"blue.400"} to="/auth/forgot">
                            Forgot Password?
                          </Link>
                        </Text>
                      </FormControl>

                      <Flex
                        columns={{ sm: 2, md: 2 }}
                        direction={{ sm: "row", md: "row" }}
                        gaps="2">
                        <Box>
                          <Input
                            variant="main"
                            type={"text"}
                            maxLength={4}
                            defaultValue={captchaToken}
                            color="black"
                            onChange={(e) => {
                              setCaptchaToken(e.target.value);
                            }}
                            placeholder="Enter Captcha"
                          />
                        </Box>
                        <Box>
                          <LoadCanvasTemplate reloadText="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Refresh" reloadColor="#008081" />
                        </Box>
                      </Flex>
                    </Stack>

                    <Stack spacing={3} mt={4}>
                      <Button
                        variant="brand"
                        isLoading={isSubmitting}
                        loadingText="Wait"
                        mb="10px"
                        type="submit"
                      >
                        Login
                      </Button>

                      {showActivation && <Text fontSize={{ sm: "sm", md: "md" }} mb="2" onClick={handleResendActivation}> <b>Resend Email Activation</b></Text>}
                      <Text align={"center"} color={"dark.600"} fontSize="md">
                        Don't have an account? &nbsp;
                        <Text color="brand.500" as="span">
                          <Link to={"/auth/signup"}>
                            Signup
                          </Link>
                        </Text>
                      </Text>
                    </Stack>
                  </Form>
                )}
              </Formik>
            </Box>
          </Stack>
        </Flex>
      </DefaultAuth>
    </>
  );
}

const TextWithCircle = ({ text, isSuccess }) => {
  return (
    <Flex gaps="2">
      <Box
        style={{
          width: "11px",
          height: "11px",
          mr: 0.5,
          borderRadius: "50%",
          bgcolor: isSuccess ? "#00808133" : "#FF6F8133",
        }}
      />
      <Text
        fontSize={{
          color: isSuccess ? "#008081" : "#FF6F81",
          fontSize: 10,
        }}
      >
        {text}
      </Text>
    </Flex>
  );
};

export default Index;
