import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  Image,
  InputRightElement,
  Stack,
  Button,
  Heading,
  Text,
  Checkbox,
  useToast,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { FaAngleLeft } from "react-icons/fa";
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import DefaultAuth from "layouts/auth/Default";
import CustomPhoneInput from "./components/customPhoneInput";
import { UserRegister } from "services/userService";

import illustrationImage from "assets/images/login1.png";
import Logo from "assets/images/logo.svg";

function Index() {
  const toast = useToast();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [agreeWithTerms, setAgreeWithTerms] = useState(false);

  const validationSchema = Yup.object({
    organizationName: Yup.string().min(2).max(200).required().label("Organization Name"),
    firstName: Yup.string().min(2).max(30).required().label("First Name"),
    lastName: Yup.string().min(2).max(30).required().label("Last Name"),
    phone: Yup.string().min(6).max(16).required().label("Mobile Number"),
    email: Yup.string().email().min(3).max(150).required().label("Email Address"),
    password: Yup
      .string()
      .min(6)
      .max(50)
      .matches(
        /^(?=.*[a-z])/,
        "Must contain at least one lowercase character"
      )
      .matches(
        /^(?=.*[A-Z])/,
        "Must contain at least one uppercase character"
      )
      .matches(/^(?=.*[0-9])/, "Must contain at least one number")
      .matches(
        /^(?=.*[!@#%&])/,
        "Must contain at least one special character ?=.*!@#%&"
      )
      .required()
      .label("Password"),
    confirmPassword: Yup
      .string()
      .min(6)
      .max(50)
      .required()
      .label("Confirm Password")
      .oneOf([Yup.ref("password")], "Password doesn't corresponds")
  });

  const handleRegister = async (user, setSubmitting) => {
    try {
      if (!agreeWithTerms) {
        toast({
          title: "You need to accept the terms and conditions to proceed",
          status: "info",
          duration: 4000,
          position: "top-right",
          isClosable: true,
        });
        setSubmitting(false);
        return;
      }
      const response = await UserRegister(user);
      setSubmitting(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        navigate("/auth/verifyemail");
        return;
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setSubmitting(false);
      return;
    }
  };

  return (
    <>
      <Helmet>
        <title>Signup | Create an account</title>
        <meta name="description" content="Signup for an account" />
        <meta name="keywords" content="" />
      </Helmet>
      <DefaultAuth illustrationImage={illustrationImage} heading="Verification of Academic Records">

        <Flex width={"100%"} justify={"center"}>
          <Stack
            spacing={4}
            mx={"auto"}
            mt={{ sm: "30px", md: "0px" }}
            w={{ base: "95%", sm: "90%", md: "60%", lg: "50%" }}
          >
            <Stack p={2} align={"left"}>
              <Image src={Logo} width="70px" height="auto" />
              <Heading fontFamily="ManropeSemiBold" fontWeight={"bold"} size={{ sm: "sm", md: "md" }} textAlign={"left"} color="#45005E">
                Create Account
              </Heading>
            </Stack>
            <Box p={2} width="100%">
              <Formik
                initialValues={{
                  organizationName: "",
                  firstName: "",
                  lastName: "",
                  phone: "",
                  email: "",
                  password: "",
                  confirmPassword: "",
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  handleRegister(values, setSubmitting);
                }}
              >
                {({ handleSubmit, handleChange, handleBlur, values, isSubmitting }) => (
                  <Form onSubmit={handleSubmit}>

                    {
                      !showPasswordForm &&
                      <Stack spacing={4} width="100%">
                        <FormControl id="organizationName" isRequired>
                          <FormLabel fontFamily="ManropeSemiBold">Organization Name</FormLabel>
                          <Input
                            type="text"
                            placeholder="Enter Organization Name"
                            variant="main"
                            name="organizationName"
                            value={values.organizationName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage name="organizationName" component="div" style={{ color: 'red' }} />
                        </FormControl>

                        <FormControl id="firstName" isRequired>
                          <FormLabel fontFamily="ManropeSemiBold">First Name</FormLabel>
                          <Input
                            type="text"
                            placeholder="Enter First Name"
                            variant="main"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage name="firstName" component="div" style={{ color: 'red' }} />
                        </FormControl>

                        <FormControl id="lastName" isRequired>
                          <FormLabel fontFamily="ManropeSemiBold">Last Name</FormLabel>
                          <Input
                            variant="main"
                            type="text"
                            name="lastName"
                            placeholder="Enter Last Name"
                            value={values.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage name="lastName" component="div" style={{ color: 'red' }} />
                        </FormControl>

                        <FormControl id="email" isRequired>
                          <FormLabel fontFamily="ManropeSemiBold">Work Email</FormLabel>
                          <Input
                            variant="main"
                            type="email"
                            name="email"
                            placeholder="Enter Work Email Address"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <ErrorMessage name="email" component="div" style={{ color: 'red' }} />
                        </FormControl>

                        <FormControl id="phone" isRequired>
                          <FormLabel fontFamily="ManropeSemiBold">Mobile Number</FormLabel>
                          <PhoneInput
                            international
                            placeholder="Enter phone number"
                            defaultCountry="NG"
                            value={values.phone}
                            onChange={(value) => handleChange({ target: { name: 'phone', value } })}
                            inputComponent={CustomPhoneInput} />
                        </FormControl>
                      </Stack>
                    }

                    {
                      showPasswordForm &&
                      <Stack spacing={4} width="100%">
                        <Box width="100%">
                          <Flex fontSize={{ sm: "sm", md: "md" }} onClick={() => setShowPasswordForm(false)}><Icon as={FaAngleLeft} width="25" height="25" />&nbsp;<Text as="span" fontSize={{ sm: "sm", md: "md" }}>Back</Text></Flex>
                        </Box>
                        <FormControl id="password" isRequired>
                          <FormLabel fontFamily="ManropeSemiBold">Password</FormLabel>
                          <InputGroup>
                            <Input
                              type={showPassword ? "text" : "password"}
                              variant="main"
                              maxLength={100}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Enter Password"
                            />
                            <InputRightElement h={"full"}>
                              <Button
                                variant={"ghost"}
                                onClick={() =>
                                  setShowPassword((showPassword) => !showPassword)
                                }
                              >
                                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                          <ErrorMessage name="password" component="div" style={{ color: 'red' }} />
                        </FormControl>

                        <FormControl id="confirmPassword" isRequired>
                          <FormLabel fontFamily="ManropeSemiBold">Confirm Password</FormLabel>
                          <InputGroup>
                            <Input
                              type={showPassword ? "text" : "password"}
                              variant="main"
                              maxLength={100}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Re-enter Password"
                            />
                            <InputRightElement h={"full"}>
                              <Button
                                variant={"ghost"}
                                onClick={() =>
                                  setShowPassword((showPassword) => !showPassword)
                                }
                              >
                                {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                              </Button>
                            </InputRightElement>
                          </InputGroup>
                          <ErrorMessage name="confirmPassword" component="div" style={{ color: 'red' }} />
                        </FormControl>
                      </Stack>
                    }


                    <Stack spacing={3} mt={4}>
                      <Box>
                        <Checkbox
                          value={agreeWithTerms}
                          checked={agreeWithTerms}
                          onChange={(event) => { setAgreeWithTerms(event.target.checked) }}
                        >
                          I agree with <u><a href="https://akowe.app/terms" target="_blank" rel="noopener noreferrer">Terms and Conditions</a></u>
                        </Checkbox>

                      </Box>
                      {
                        !showPasswordForm ?
                          <Button
                            variant="brand"
                            isLoading={isSubmitting}
                            loadingText="Wait"
                            onClick={(e) => {
                              e.preventDefault();
                              setShowPasswordForm(true);
                            }}
                            mb="10px"
                            type="submit"
                          >
                            Proceed
                          </Button> : <Button
                            variant="brand"
                            isLoading={isSubmitting}
                            loadingText="Wait.."
                            mb="10px"
                            type="submit"
                          >
                            Register Account
                          </Button>
                      }

                      <Text align={"center"} color={"dark.600"} fontSize="md">
                        Already have an account? &nbsp;
                        <Text color="brand.500" as="span">
                          <Link to={"/auth/login"}>
                            Login
                          </Link>
                        </Text>
                      </Text>

                    </Stack>
                  </Form>
                )}
              </Formik>
            </Box>
          </Stack>
        </Flex>
      </DefaultAuth>
    </>
  );
}

const TextWithCircle = ({ text, isSuccess }) => {
  return (
    <Flex gaps="2">
      <Box
        style={{
          width: "11px",
          height: "11px",
          mr: 0.5,
          borderRadius: "50%",
          bgcolor: isSuccess ? "#00808133" : "#FF6F8133",
        }}
      />
      <Text
        fontSize={{
          color: isSuccess ? "#008081" : "#FF6F81",
          fontSize: 10,
        }}
      >
        {text}
      </Text>
    </Flex>
  );
};

export default Index;
