import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from 'react-router-dom';
import {
  Box,
  Text,
  Tag,
  Center,
  Button,
  Heading,
  HStack,
  VStack,
  Input,
  Flex,
  Stack,
  Link,
  SimpleGrid,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Icon,
  FormControl,
  FormLabel,
  Select,
  Skeleton,
  SkeletonText,
  useToast,
  useDisclosure
} from "@chakra-ui/react";
import { PDFViewer } from '@react-pdf/renderer';
import Card from "components/card/Card";
import CardBg from "assets/images/ribbon.svg";
import { GetInstitution } from "services/institutionService";
import { GetCountries } from "services/miscService";
import { FetchCertificate, GetSearchHistory, DownloadCertificate } from "services/certificateService";
import { GetOrganization } from "services/organizationService";
import { Checkout, VerifyPayment } from "services/paymentService";
import { FaBook, FaCalendarDay, FaSearch, FaUniversity, FaUser } from "react-icons/fa";
import { DownloadablePDF, generateDownloadLink } from "helpers/certificate";
import { useLocation, useSearchParams } from "react-router-dom";
const { DateTime } = require("luxon");

export default function Dashboard() {
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const { isOpen: isHistoryDrawerOpen, onOpen: onHistoryDrawerOpen, onClose: onHistoryDrawerClose } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const privileges = userState.hasOwnProperty("privileges") ? userState.privileges : [];

  const [searchData, setSearchData] = useState({
    candidateName: "",
    institution: "",
    country: ""
  });

  const [historyData, setHistoryData] = useState(null);
  const [creditData, setCreditData] = useState({
    amount: 0,
    method: ""
  });
  const [countries, setCountries] = useState([]);
  const [institutions, setIntitutions] = useState([]);
  const [organization, setOrganization] = useState(null);
  const [records, setRecords] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(10);
  const [orderBy, setOrderBy] = useState("createdAt");

  const [paymentLoading, setPaymentLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [histories, setHistories] = useState(null);
  const [certificateId, setCertificateId] = useState("");
  const [record, setRecord] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [pdfDownloadLoading, setPdfDownloadLoading] = useState(false);

  const getInstitutions = async () => {
    try {
      setLoading(true);
      const response = await GetInstitution(token);
      setLoading(false);
      if (response.success) {
        setIntitutions(response?.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const getCountries = async () => {
    try {
      setLoading(true);
      const response = await GetCountries(token);
      setLoading(false);
      if (response.success) {
        setCountries(response?.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const getOrganization = async () => {
    try {
      setLoading(true);
      const response = await GetOrganization(token);
      setLoading(false);
      if (response.success) {
        setOrganization(response?.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getSearchHistory = async () => {
    try {
      setHistoryLoading(true);
      const pagination = {
        from: from,
        limit: limit,
        orderBy: orderBy,
        sortOrder: sortOrder,
      };
      const filter = {
        deleted: false,
      };
      const response = await GetSearchHistory(pagination, filter, token);
      setHistoryLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setPageCount(response.data.count / limit);
        setHistories(response?.data?.searches);
      }
    } catch (error) {
      setHistoryLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const response = await FetchCertificate(searchData, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setRecords(response.data);
        getSearchHistory();
        getOrganization();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  }

  const handlePurchase = async () => {
    if (!creditData.amount) {
      toast({
        status: "error",
        title: "Invalid amount",
        duration: 9000,
        position: "top-right",
        isClosable: true
      });
      return;
    }

    if (creditData.method.trim() === "") {
      toast({
        status: "error",
        title: "Select a payment method",
        duration: 9000,
        position: "top-right",
        isClosable: true
      });
      return;
    }
    try {
      setPaymentLoading(true);
      const response = await Checkout(creditData, token);
      setPaymentLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;

      }
      if (response.success) {
        creditData.method === "paystack" ? (window.location.href = response.data.authorization_url) : (window.location.href = response.data.link);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setPaymentLoading(false);
    }
  };

  const verifyPayment = async ({ method, transaction_id, reference }) => {
    try {
      setLoading(true);
      const paymentData = {};
      if (method === "paystack") {
        paymentData.transactionRef = reference;
        paymentData.method = "paystack"
      } else {
        paymentData.transactionId = transaction_id;
        paymentData.transactionRef = reference;
        paymentData.method = "flutterwave"
      }
      const response = await VerifyPayment(paymentData, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        getOrganization();

        if (response.data.type === "NEW") {
          toast({
            title: response.message || "Payment was successful",
            status: "success",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
          localStorage.setItem("showSuccessMessage", true);
        }
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const pdfDownload = async () => {
    try {
      setPdfDownloadLoading(true);
      const isDownloaded = await generateDownloadLink(pdf, record?.certificate?._doc?.name);
      setPdfDownloadLoading(false);
      if (!isDownloaded) {
        toast({
          title: "Error occured while downloaing report",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true
        })
      }
      toast({
        title: "Verification Report Downloaded Successfully",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true
      })

    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setDownloadLoading(false);
    }
  }

  const downloadCertificate = async (certificateId = null) => {
    // cancel the request if no certificate ID to avoid API error
    if (!certificateId) return;
    try {
      setDownloadLoading(true);
      const response = await DownloadCertificate(certificateId, token);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setRecord(response.data);
      const pdf = <DownloadablePDF certificate={response.data?.certificate} highlightedPage={response.data?.highlightedPage} yearBook={response.data?.yearBook} />
      setPdf(pdf);
      onOpen();

      setDownloadLoading(false);
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setDownloadLoading(false);
    }
  };

  useEffect(() => {
    /*if(!privileges.includes("Manage Certificates") && !privileges.includes("Admin")){
      toast({
        title: "You are not allowed",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      navigate("/admin/dashboard");
      return;
    }*/
    const successFlag = localStorage.getItem("showSuccessMessage");
    if (successFlag) {
      // Show the success message if the flag is found
      toast({
        title: "Payment was successful",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      // Remove the flag so the message only shows once
      localStorage.removeItem("showSuccessMessage");
    }

    getInstitutions();
    getCountries();
    getOrganization();
    getSearchHistory();
  }, []);

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tx_ref = searchParams.get('tx_ref');
    const transaction_id = searchParams.get('transaction_id');
    const reference = searchParams.get('reference');

    console.log("reference", reference)

    if (tx_ref && transaction_id) {
      // Verify Payment via flutterwave
      verifyPayment({
        method: "flutterwave",
        reference: tx_ref,
        transaction_id
      });
    }
    else if (reference) {
      // Verify Payment via paystack
      verifyPayment({
        method: "paystack",
        reference
      });
    }
  }, []);

  const formatTime = (timestamp) => {
    const myDateTime = DateTime.fromISO(timestamp);
    return myDateTime.toLocaleString(DateTime.DATETIME_MED);
  };

  return (
    <>
      <Modal onClose={onClose} size={"2xl"} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{record && record?.certificate && record?.certificate._doc && record?.certificate._doc.name ? record?.certificate?._doc?.name + " Full Report Preview" : "Full Report Preview"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody minH={"700px"}>
            <Box
              style={{ width: "100%", height: "700px" }}
            >
              <PDFViewer width="100%" height="700px">
                {record && record?.certificate && <DownloadablePDF certificate={record?.certificate} highlightedPage={record?.highlightedPage} yearBook={record?.yearBook} />}
              </PDFViewer>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="brand" isLoading={pdfDownloadLoading} loadingText="..Wait" onClick={pdfDownload}>Download</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Drawer isOpen={isHistoryDrawerOpen} placement="right" onClose={onHistoryDrawerClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color="#45005E"> Search History for {historyData && historyData.candidateName + " (" + historyData.resultCount + ")"} </DrawerHeader>
          <DrawerBody>
            {
              historyData &&
              <Accordion mt="4">
                {
                  historyData.data && historyData.data.length > 0 && historyData.data.map((record) => (
                    <AccordionItem p="2" bg="brand.100" borderWidth={0} borderRadius="15" mb="4">
                      <h2>
                        <AccordionButton>
                          <Box as='span' flex='1' textAlign='left' color="brand.500">
                            <Text fontSize={{ sm: "sm", md: "md" }} fontWeight="bold">{record.name}</Text>
                          </Box>
                          <AccordionIcon width="30px" height="30px" color="brand.500" />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Card background="brand.100" width="100%">
                          <VStack columns={{ sm: 2, md: 4 }} spacing="4" width="100%">
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}><Icon as={FaUser} width="18px" height="18px" />&nbsp; Candidate's Name</Tag> <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark">{record.name}</Text></Flex></Box>
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}><Icon as={FaUniversity} width="18px" height="18px" />&nbsp; Institution</Tag><Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark">{record?.institutionName}</Text></Flex></Box>
                            {/* <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Faculty</Tag><Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark">{record?.faculty ? record?.faculty : "N/A"}</Text></Flex></Box>*/}
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Course</Tag> <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark">{record.course ? record.course : "N/A"}</Text></Flex></Box>
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Degree</Tag>  <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark"> {record.degree ? record.degree : "N/A"}</Text></Flex></Box>
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Grade</Tag>  <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark"> {record.grade ? record.grade : "N/A"}</Text></Flex></Box>
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Year</Tag>  <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark"> {record.year ? record.year : "N/A"}</Text></Flex></Box>
                            {record.affliatedInstitution && <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Year</Tag>  <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark"> {record.affliatedInstitution ? record.affliatedInstitution : "N/A"}</Text></Flex></Box>}
                          </VStack>
                          <Box mt="4" textAlign={"right"}>
                            <Button
                              variant="primary"
                              background="brand.500"
                              isLoading={downloadLoading}
                              loadingText="Wait..."
                              size="sm"
                              onClick={(e) => {
                                setCertificateId(record._id);
                                downloadCertificate(record._id);
                              }}>Download Full Report</Button>
                          </Box>
                        </Card>
                      </AccordionPanel>
                    </AccordionItem>))}
              </Accordion>
            }
          </DrawerBody>
        </DrawerContent>
      </Drawer>


      <Drawer isOpen={isDrawerOpen} placement="right" onClose={onDrawerClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color="#45005E">Balance TopUp</DrawerHeader>
          <DrawerBody>
            <Card background="gray.100">
              <Box mt="2">
                <FormControl>
                  <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Amount</FormLabel>
                  <Input
                    variant="main"
                    maxLength={10}
                    placeholder="Amount"
                    defaultValue={creditData && creditData.amount}
                    onChange={(e) => {
                      setCreditData(prevCredit => ({
                        ...prevCredit,
                        amount: e.target.value,
                      }));
                    }}
                  />
                </FormControl>
              </Box>
              <Box mt="3">
                <FormControl>
                  <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Payment Method</FormLabel>
                  <Select variant="main" defaultValue={creditData && creditData.method} onChange={(e) => setCreditData((prev) => ({ ...prev, method: e.target.value }))}>
                    <option value={" "}>
                      --- Select Payment Method ---
                    </option>
                    <option value={"flutterwave"}>
                      <HStack>
                        <span>Flutterwave</span>
                      </HStack>
                    </option>
                    <option value={"paystack"}>
                      <HStack>
                        <span>Paystack</span>
                      </HStack>
                    </option>
                  </Select>
                </FormControl>
              </Box>

              <Box textAlign="left" mt="4" width="100%">
                <Button
                  variant="brand"
                  width="150px"
                  isLoading={paymentLoading}
                  loadingText="Wait..."
                  onClick={handlePurchase}
                >Proceed
                </Button>
              </Box>
            </Card>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Box mt={{ sm: "40px", md: "80px" }} width="100%" mb="70">
        <SimpleGrid
          columns={{ base: 2, md: 3, lg: 4 }}
          gap='20px'
          mb='20px'>
          <Box
            minH="150"
            borderRadius={"15"}
            borderTopWidth={"1px"}
            borderTopColor="brand.200"
            borderBottomWidth={"1px"}
            borderBottomColor="brand.200"
            borderLeftWidth={"1px"}
            borderLeftColor="gray.100"
            borderRightWidth={"1px"}
            borderRightColor="gray.200"
            backgroundImage={CardBg}
            backgroundSize={"cover"}
            backgroundPosition={"center"}
          >
            <Center width="100%" mt="6">
              <Box width="80%">
                <Text fontSize={{ sm: "lg", md: "2xl" }}>Balance</Text>
                <Heading size={{ sm: "md", md: "lg" }} color="brand.500">{organization && "NGN " + new Intl.NumberFormat('en-US', { style: 'decimal' }).format(organization.balance)} </Heading>
              </Box>
            </Center>
          </Box>

          <Box
            h="150">
            <Center width="100%" mt="3">
              <Button variant="brand" onClick={onDrawerOpen}>
                Buy Credit
              </Button>
            </Center>
            {organization && (organization.freeSearch > 0 || organization.freeDownload > 0) &&
              <Box width="100%" mt="3">
                <Text size={{ sm: "sm" }}><Tag background="brand.500" color="#ffffff">{organization && organization.freeSearch ? organization.freeSearch : 0} Free Searches</Tag> </Text>
                <Text size={{ sm: "sm" }} mt="2"><Tag background="brand.500" color="#ffffff">{organization && organization.freeDownload ? organization.freeDownload : 0} Free Report Downloads</Tag> </Text>
              </Box>
            }
          </Box>

          <Box h="150"> 
             <Center width="100%" mt="2">
                <Flex> <Text color="brand.500">To see a full list of currently available data, please visit the <NavLink to="/app/status"><b>Status Page</b></NavLink></Text></Flex>
             </Center>
          </Box>

        </SimpleGrid>

        <Stack
          direction={{ base: 'column', md: 'row' }} // column for sm screens, row for md+ screens
          spacing="10"
          mt="10">
          <Box
            width={{ base: "100%", md: "70%" }}>
            <Box p="10" background="brand.100" borderRadius="10" minHeight={"400"}>
              <Heading size={{ sm: "sm", md: "md" }} color="#45005E">Credential Search</Heading>
              <Text mt="2" fontSize={{ sm: "md", md: "lg" }} color="#45005E">For optimum results, please search with the candidate's any two names.</Text>
              <SimpleGrid
                width="100%"
                columns={{ base: 1, md: 2 }}
                spacing="6"
                mb="15"
                mt="5">
                <Box>
                  <FormControl isRequired>
                    <FormLabel fontWeight="bold">Country</FormLabel>
                    <Select
                      bg="white"
                      variant="main"
                      placeholder="Select Country"
                      value={searchData.country}
                      onChange={(e) => {
                        setSearchData(prevSearchData => ({
                          ...prevSearchData,
                          country: e.target.value
                        }));
                      }}
                    >
                      {
                        countries.length > 0 &&
                        countries.map((country) => (
                          <option key={country._id} value={country._id}>
                            {country.countryName}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  <FormControl isRequired>
                    <FormLabel fontWeight="bold">School/Institution</FormLabel>
                    <Select
                      bg="white"
                      variant="main"
                      placeholder="Select School/Instituition"
                      value={searchData.institution}
                      onChange={(e) => {
                        setSearchData(prevSearchData => ({
                          ...prevSearchData,
                          institution: e.target.value
                        }));
                      }}
                    >
                      {
                        institutions.length > 0 &&
                        institutions.map((institution) => (
                          <option key={institution._id} value={institution._id}>
                            {institution.institutionName}
                          </option>
                        ))}
                    </Select>
                  </FormControl>
                </Box>
              </SimpleGrid>

              <SimpleGrid
                width="100%"
                columns={{ base: 1, md: 2 }}
                spacing="6"
                mb="20"
                mt="5">
                <Box>
                  <FormControl isRequired>
                    <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Candidate's Fullname</FormLabel>
                    <Input
                      bg="white"
                      variant="main"
                      maxLength={200}
                      placeholder="Candidate's Name"
                      defaultValue={searchData.candidateName}
                      onChange={(e) => {
                        setSearchData(prevSearchData => ({
                          ...prevSearchData,
                          candidateName: e.target.value,
                        }));
                      }}
                    />
                  </FormControl>
                </Box>
                <Box>
                  <FormLabel fontWeight="bold">&nbsp;</FormLabel>
                  <Button variant={"brand"} onClick={handleSubmit} isLoading={loading} loadingText={"...Wait"}> Search </Button>
                </Box>
              </SimpleGrid>

              {
                !records &&
                <Box mt="5" width="100%" textAlign={"center"}>
                  {/*<Heading size={{ sm: "sm", md: "md" }} color="gray.500">Enter credential information to view certificates.</Heading>*/}
                  <Icon mt="4" as={FaSearch} width="40px" height="40px" color="gray.500" />
                </Box>
              }

              {records && records.length === 0 &&
                <Box mt="5" width="100%" textAlign={"center"}>
                  <Heading size={{ sm: "sm", md: "md" }} color="red.300">No record found on your searched candidate</Heading>
                  <Text fontSize={{ sm: "md", md: "lg" }} color="gray.500"> Try to conduct a new search.</Text>
                  <Icon mt="4" as={FaSearch} width="40px" height="40px" color="red.300" />
                </Box>
              }
            </Box>

            <Box width="100%" pt="10">
              {records && <Heading size={{ sm: "sm", md: "md" }} color="#45005E">Records ({records.length})</Heading>}
              <Accordion mt="4">
                {
                  !loading && records && records.map((record) => (
                    <AccordionItem p="2" bg="brand.100" borderWidth={0} borderRadius="15" mb="4">
                      <h2>
                        <AccordionButton>
                          <Box as='span' flex='1' textAlign='left' color="brand.500">
                            <Text fontSize={{ sm: "sm", md: "md" }} fontWeight="bold">{record.name}</Text>
                          </Box>
                          <AccordionIcon width="30px" height="30px" color="brand.500" />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Card background="brand.100" width="100%">
                          <VStack columns={{ sm: 2, md: 4 }} spacing="4" width="100%">
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}><Icon as={FaUser} width="18px" height="18px" />&nbsp; Candidate's Name</Tag> <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark">{record.name}</Text></Flex></Box>
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}><Icon as={FaUniversity} width="18px" height="18px" />&nbsp; Institution</Tag><Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark">{record?.institutionName}</Text></Flex></Box>
                            {/*<Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Faculty</Tag><Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark">{record?.faculty ? record?.faculty : "N/A"}</Text></Flex></Box>*/}
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Course</Tag> <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark">{record.course ? record.course : "N/A"}</Text></Flex></Box>
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Degree</Tag>  <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark"> {record.degree ? record.degree : "N/A"}</Text></Flex></Box>
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Grade</Tag>  <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark"> {record.grade ? record.grade : "N/A"}</Text></Flex></Box>
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Year</Tag>  <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark"> {record.year ? record.year : "N/A"}</Text></Flex></Box>
                            {record.affliatedInstitution && <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Year</Tag>  <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark"> {record.affliatedInstitution ? record.affliatedInstitution : "N/A"}</Text></Flex></Box>}
                          </VStack>
                          <Box mt="4" textAlign={"right"}>
                            <Button
                              variant="primary"
                              background="brand.500"
                              isLoading={downloadLoading}
                              loadingText="..Wait"
                              size="sm"
                              onClick={(e) => {
                                setCertificateId(record._id);
                                downloadCertificate(record._id);
                              }}>Download Full Report</Button>
                          </Box>
                        </Card>
                      </AccordionPanel>
                    </AccordionItem>))}
              </Accordion>
              {
                loading && <LoadingHistory />
              }
            </Box>
          </Box>

          <Box
            width={{ base: "100%", md: "30%" }}
            p="10" borderRadius="10" borderWidth={4} borderColor={"brand.200"} minHeight={"600"}>
            <Box width="100%"><Heading size={{ sm: "sm", md: "md" }} color="#45005E">Search History</Heading></Box>
            <VStack width="100%" spacing="5" mt="4">
              {!historyLoading && histories && histories.map((history) => (
                <HStack width="100%">
                  <Box width="90%">
                    <Heading size={{ sm: "sm", md: "md" }} color="#45005E" textDecoration={"underline"} onClick={() => {
                      setHistoryData(history);
                      onHistoryDrawerOpen();
                    }}>{history.candidateName}</Heading>
                    <Text fontSize={{ sm: "sm", md: "md" }}> {formatTime(history.createdAt)}</Text>
                  </Box>
                  <Box width="10%">
                    <Text fontSize={{ sm: "sm", md: "md" }}> {history.resultCount} </Text>
                  </Box>
                </HStack>))
              }

              {
                historyLoading && <LoadingHistory />
              }

              <Box textAlign={"center"} mt="4">
                <Text fontSize={{ sm: "sm", md: "md" }} textDecoration={"underline"}><Link to={"/app/history"}>View All</Link></Text>
              </Box>
            </VStack>

            {
              !histories &&
              <Box mt="20" width="100%" textAlign={"center"}>
                <Text size={{ sm: "sm", md: "md" }} color="gray.500"> No Credential search has been performed.</Text>
                <Icon mt="4" as={FaSearch} width="30px" height="30px" color="gray.500" />
              </Box>
            }

          </Box>
        </Stack>
      </Box>
    </>
  );
}

const LoadingHistory = () => {
  return (
    <>
      <Card align="center" p="5px">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>

      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};