import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Center,
  SimpleGrid,
  Button,
  FormControl,
  FormLabel,
  Input,
  Tabs, TabList, TabPanels, Tab, TabPanel,
  useToast
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import {
  GetSettings, UpdateSettings
} from "services/miscService";
import { checkprivileges } from "helpers/utils";

export default function Roles() {
  const toast = useToast();
  const navigate = useNavigate();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const privileges = userState.hasOwnProperty("privileges")
    ? userState.privileges
    : [];
  const [loading, setLoading] = useState(false);
  const [settings, setSettings] = useState(null);

  const getSettings = async () => {
    try {
      setLoading(true);
      const response = await GetSettings(token);
      setLoading(false);
      if (response.error) {
        toast({
          title: response.error || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (!response.success) {
        toast({
          title: response.message || "An error occured",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setSettings(response.data);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const handleUpdateSettings = async () => {
    try {
      setLoading(true);
      const response = await UpdateSettings(settings, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message,
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
      }
      getSettings();
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    !checkprivileges(privileges, ["Admin"]) && (toast({ title: "You are not allowed", status: "error", duration: 9000, position: "top-right", isClosable: true, }) && navigate("/admin/dashboard"));
    getSettings();
  }, []);

  return (
    <>
      <Center width="100%">
        <Box mt={{ sm: "40px", md: "100px" }} width={{ sm: "90%", md: "50%" }} mb="20">
          <Tabs>
            <TabList>
              <Tab>Platform Commission</Tab>
              <Tab>Institution Commission</Tab>
              <Tab>Partner Commission</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <SimpleGrid
                  width="100%"
                  columns={{ base: 1, md: 2 }}
                  spacing="4"
                  mb="15"
                  mt="5">
                  <Box>
                    <FormControl isRequired>
                      <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Platform Query</FormLabel>
                      <Input
                        variant="main"
                        maxLength={200}
                        type="text"
                        placeholder="Enter Platform Query Amount"
                        defaultValue={settings && settings.platformQueryCommission}
                        onChange={(e) => {
                          setSettings(prevSettings => ({
                            ...prevSettings,
                            platformQueryCommission: e.target.value,
                          }));
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl isRequired>
                      <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Platform Download</FormLabel>
                      <Input
                        variant="main"
                        maxLength={200}
                        type="text"
                        placeholder="Enter Platform Download Amount"
                        defaultValue={settings && settings.platformDownloadCommission}
                        onChange={(e) => {
                          setSettings(prevSettings => ({
                            ...prevSettings,
                            platformDownloadCommission: e.target.value,
                          }));
                        }}
                      />
                    </FormControl>
                  </Box>
                </SimpleGrid>
              </TabPanel>

              <TabPanel>
                <SimpleGrid
                  width="100%"
                  columns={{ base: 1, md: 2 }}
                  spacing="4"
                  mb="15"
                  mt="5">
                  <Box>
                    <FormControl isRequired>
                      <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Institution Query</FormLabel>
                      <Input
                        variant="main"
                        maxLength={200}
                        type="text"
                        placeholder="Enter Institution Query Amount"
                        defaultValue={settings && settings.institutionQueryCommission}
                        onChange={(e) => {
                          setSettings(prevSettings => ({
                            ...prevSettings,
                            institutionQueryCommission: e.target.value,
                          }));
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl isRequired>
                      <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Institution Download</FormLabel>
                      <Input
                        variant="main"
                        maxLength={200}
                        type="text"
                        placeholder="Enter Institution Download Amount"
                        defaultValue={settings && settings.institutionDownloadCommission}
                        onChange={(e) => {
                          setSettings(prevSettings => ({
                            ...prevSettings,
                            institutionDownloadCommission: e.target.value,
                          }));
                        }}
                      />
                    </FormControl>
                  </Box>
                </SimpleGrid>
              </TabPanel>

              <TabPanel>
                <SimpleGrid
                  width="100%"
                  columns={{ base: 1, md: 2 }}
                  spacing="4"
                  mb="15"
                  mt="5">
                  <Box>
                    <FormControl isRequired>
                      <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Partner Query</FormLabel>
                      <Input
                        variant="main"
                        maxLength={200}
                        type="text"
                        placeholder="Enter Partner Query Amount"
                        defaultValue={settings && settings.organizationQueryCommission}
                        onChange={(e) => {
                          setSettings(prevSettings => ({
                            ...prevSettings,
                            organizationQueryCommission: e.target.value,
                          }));
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box>
                    <FormControl isRequired>
                      <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Partner Download</FormLabel>
                      <Input
                        variant="main"
                        maxLength={200}
                        type="text"
                        placeholder="Enter Partner Download Amount"
                        defaultValue={settings && settings.organizationDownloadCommission}
                        onChange={(e) => {
                          setSettings(prevSettings => ({
                            ...prevSettings,
                            organizationDownloadCommission: e.target.value,
                          }));
                        }}
                      />
                    </FormControl>
                  </Box>
                </SimpleGrid>
              </TabPanel>
            </TabPanels>
          </Tabs>

          <Box width="100%" textAlign={"center"} mt="2">
            <Button
              variant="brand"
              size="md"
              onClick={handleUpdateSettings}
            >Save</Button>
          </Box>

        </Box>
      </Center>
    </>
  );
}
