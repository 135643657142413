import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  HStack,
  Box,
  Table,
  Thead,
  Tbody,
  SimpleGrid,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Center,
  Button,
  Heading,
  Input,
  Select,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  FormControl,
  FormLabel,
  useToast,
  useDisclosure
} from "@chakra-ui/react";
import Card from "components/card/Card";
import Paginate from "components/navigation/Paginate.js";
import CardBg from "assets/images/ribbon.svg";
import { Checkout, VerifyPayment, GetPayments } from "services/paymentService";
import { GetOrganization } from "services/organizationService";
const { DateTime } = require("luxon");

const formatTime = (timestamp) => {
  const myDateTime = DateTime.fromISO(timestamp);
  return myDateTime.toLocaleString(DateTime.DATETIME_MED);
};

export default function Index() {
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const { isOpen: isDrawerOpen, onOpen: onDrawerOpen, onClose: onDrawerClose } = useDisclosure();
  const privileges = userState.hasOwnProperty("privileges") ? userState.privileges : [];

  const [creditData, setCreditData] = useState({
    amount: 0,
    method: ""
  });

  const [organization, setOrganization] = useState(null);
  const [transactions, setTransactions] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(10);
  const [orderBy, setOrderBy] = useState("createdAt");

  const [loading, setLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);

  const getOrganization = async () => {
    try {
      setLoading(true);
      const response = await GetOrganization(token);
      setLoading(false);
      if (response.success) {
        setOrganization(response?.data);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const getTransactions = async () => {
    try {
      setLoading(true);
      const pagination = {
        from: from,
        limit: limit,
        orderBy: orderBy,
        sortOrder: sortOrder,
      };
      const filter = {
        deleted: false,
      };
      const response = await GetPayments(pagination, filter, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setPageCount(response.data.count / limit);
        setTransactions(response?.data?.payments);
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const handlePurchase = async () => {
    if (!creditData.amount) {
      toast({
        status: "error",
        title: "Invalid amount",
        duration: 9000,
        position: "top-right",
        isClosable: true

      });

      return;
    }

    if (creditData.method.trim() === "") {
      toast({
        status: "error",
        title: "Select a payment method",
        duration: 9000,
        position: "top-right",
        isClosable: true

      });

      return;
    }
    try {
      setPaymentLoading(true);
      const response = await Checkout(creditData, token);
      setPaymentLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;

      }
      if (response.success) {
        creditData.method === "paystack" ? (window.location.href = response.data.authorization_url) : (window.location.href = response.data.link);
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setPaymentLoading(false);
    }
  };

  const verifyPayment = async ({ method, transaction_id, reference }) => {
    try {
      setLoading(true);
      const paymentData = {};
      if (method === "paystack") {
        paymentData.transactionRef = reference;
        paymentData.method = "paystack"
      } else {
        paymentData.transactionId = transaction_id;
        paymentData.transactionRef = reference;
        paymentData.method = "flutterwave"
      }
      const response = await VerifyPayment(paymentData, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (response.success) {
        setLoading(false);
        getOrganization();

        if (response.data.type === "NEW") {
          toast({
            title: response.message || "Payment was successful",
            status: "success",
            duration: 9000,
            position: "top-right",
            isClosable: true,
          });
          localStorage.setItem("showSuccessMessage", true);
        }
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    /*if (!privileges.includes("Manage Subscription") && !privileges.includes("Admin")) {
      toast({
        title: "You are not allowed",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      navigate("/app/dashboard");
      return;
    }*/
    const successFlag = localStorage.getItem("showSuccessMessage");
    if (successFlag) {
      // Show the success message if the flag is found
      toast({
        title: "Payment was successful",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      // Remove the flag so the message only shows once
      localStorage.removeItem("showSuccessMessage");
    }
    getOrganization();
    getTransactions();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tx_ref = searchParams.get('tx_ref');
    const transaction_id = searchParams.get('transaction_id');
    const reference = searchParams.get('reference');
    console.log("reference", reference)
    if (tx_ref && transaction_id) {
      // Verify Payment via flutterwave
      verifyPayment({
        method: "flutterwave",
        reference: tx_ref,
        transaction_id
      });
    }
    else if (reference) {
      // Verify Payment via paystack
      verifyPayment({
        method: "paystack",
        reference
      });
    }
  }, []);

  return (
    <>
      <Drawer isOpen={isDrawerOpen} placement="right" onClose={onDrawerClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color="#45005E">Balance TopUp</DrawerHeader>
          <DrawerBody>

            <Card background="gray.100">
              <Box mt="2">
                <FormControl>
                  <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Amount</FormLabel>
                  <Input
                    variant="main"
                    maxLength={10}
                    placeholder="Amount"
                    defaultValue={creditData && creditData.amount}
                    onChange={(e) => {
                      setCreditData(prevCredit => ({
                        ...prevCredit,
                        amount: e.target.value,
                      }));
                    }}
                  />
                </FormControl>
              </Box>

              <Box mt="3">
                <FormControl>
                  <FormLabel fontWeight="bold" fontFamily="ManropeSemiBold">Payment Method</FormLabel>
                  <Select variant="main" defaultValue={creditData && creditData.method} onChange={(e) => setCreditData((prev) => ({ ...prev, method: e.target.value }))}>
                    <option value={" "}>
                      --- Select Payment Method ---
                    </option>
                    <option value={"flutterwave"}>
                      <HStack>
                        <span>Flutterwave</span>
                      </HStack>
                    </option>
                    <option value={"paystack"}>
                      <HStack>
                        <span>Paystack</span>
                      </HStack>
                    </option>
                  </Select>
                </FormControl>
              </Box>

              <Box textAlign="left" mt="4" width="100%">
                <Button
                  variant="brand"
                  width="150px"
                  isLoading={paymentLoading}
                  loadingText="Wait..."
                  onClick={handlePurchase}
                >Proceed
                </Button>
              </Box>
            </Card>
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Box mt={{ sm: "40px", md: "80px" }} width="100%" mb="70">

        <SimpleGrid
          columns={{ base: 2, md: 4, lg: 5 }}
          gap='20px'
          mb='20px'>
          <Box
            minH="150"
            borderRadius={"15"}
            borderTopWidth={"1px"}
            borderTopColor="brand.200"
            borderBottomWidth={"1px"}
            borderBottomColor="brand.200"
            borderLeftWidth={"1px"}
            borderLeftColor="gray.100"
            borderRightWidth={"1px"}
            borderRightColor="gray.200"
            backgroundImage={CardBg}
            backgroundSize={"cover"}
            backgroundPosition={"center"}
          >
            <Center width="100%" mt="6">
              <Box width="80%">
                <Text fontSize={{ sm: "lg", md: "2xl" }}>Balance</Text>
                <Heading size={{ sm: "md", md: "lg" }} color="brand.500">{organization && "NGN " + new Intl.NumberFormat('en-US', { style: 'decimal' }).format(organization.balance)} </Heading>
              </Box>
            </Center>
          </Box>

          <Box
            h="150">
            <Center width="100%" height="100" mt="3">
              <Button variant="brand" onClick={onDrawerOpen}>
                Buy Credit
              </Button>
            </Center>
          </Box>
        </SimpleGrid>

        <TableContainer>
          <Table variant='striped' colorScheme='brand'>
            <Thead>
              <Tr>
                <Th>Amount</Th>
                <Th>Date</Th>
              </Tr>
            </Thead>
            <Tbody>
              {
                transactions && transactions.map((transaction) => (
                  <Tr>
                    <Td>NGN {transaction.amount}</Td>
                    <Td>{formatTime(transaction.createdAt)}</Td>
                  </Tr>
                ))}

            </Tbody>

          </Table>
        </TableContainer>

        {pageCount > 0 && (
          <Box width="100%" mt="5">
            <Paginate
              pageCount={pageCount}
              setFrom={setFrom}
              getRecords={getTransactions}
            />
          </Box>
        )}

      </Box>
    </>
  );
}

