// chakra imports
import { Box, Flex, Stack } from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import React from "react";

function SidebarContent(props) {
  const { routes, onCloseSideMenu } = props;

  // SIDEBAR
  return (
    <Flex direction="column" height="100%">
      <Brand />
      <Stack direction="column" mb="auto" mt="10">
        <Box ps="10px" pe="10px" mt="2">
          <Links routes={routes} onCloseSideMenu={onCloseSideMenu} />
        </Box>
      </Stack>
    </Flex>
  );
}

export default SidebarContent;
