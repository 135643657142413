import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  InputGroup,
  InputRightElement,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  VStack,
  Flex,
  Tag,
  Button,
  SkeletonText,
  useToast,
  useDisclosure
} from "@chakra-ui/react";
import { PDFViewer } from '@react-pdf/renderer';
import Paginate from "components/navigation/Paginate.js";
import TextFilter from "components/filter/TextFilter";
import { FaSearch, FaUniversity, FaUser } from "react-icons/fa";
import { GetSearchHistory, DownloadCertificate, SearchHistory } from "services/certificateService";
import Card from "components/card/Card";
import { DownloadablePDF, generateDownloadLink } from "helpers/certificate";
const { DateTime } = require("luxon");

const formatTime = (timestamp) => {
  const myDateTime = DateTime.fromISO(timestamp);
  return myDateTime.toLocaleString(DateTime.DATETIME_MED);
};

export default function Index() {
  const toast = useToast();
  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const { isOpen: isHistoryDrawerOpen, onOpen: onHistoryDrawerOpen, onClose: onHistoryDrawerClose } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const privileges = userState.hasOwnProperty("privileges") ? userState.privileges : [];

  const [searchData, setSearchData] = useState({
    candidateName: "",
    institution: ""
  });

  const [historyData, setHistoryData] = useState(null);
  const [certificateId, setCertificateId] = useState("");
  const [searchText, setSearchText] = useState(null);
  const [searches, setSearches] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(10);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [loading, setLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [pdf, setPdf] = useState(null);
  const [record, setRecord] = useState(null);
  const [pdfDownloadLoading, setPdfDownloadLoading] = useState(false);

  const getSearchHistory = async (from) => {
    try {
      setLoading(true);
      const pagination = {
        from: from,
        limit: limit,
        orderBy: orderBy,
        sortOrder: sortOrder,
      };
      const filter = {
        deleted: false,
      };
      const response = await GetSearchHistory(pagination, filter, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setPageCount(response.data.count / limit);
        setSearches(response?.data?.searches);
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const handleSearch = async () => {
    try {
      const searchData = {
        searchText
      };
      setLoading(true);
      const response = await SearchHistory(searchData, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setSearches(response.data);
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const pdfDownload = async () => {
    try {
      setPdfDownloadLoading(true);
      const isDownloaded = await generateDownloadLink(pdf, record?.certificate?._doc?.name);
      setPdfDownloadLoading(false);
      if (!isDownloaded) {
        toast({
          title: "Error occured while downloaing report",
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true
        })
      }
      toast({
        title: "Verification Report Downloaded Successfully",
        status: "success",
        duration: 9000,
        position: "top-right",
        isClosable: true
      })

    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setDownloadLoading(false);
    }
  }

  const downloadCertificate = async (certificateId=null) => {
    // cancel the request if no certificate ID to avoid API error
    if (!certificateId) return;
    try {
      setDownloadLoading(true);
      const response = await DownloadCertificate(certificateId, token);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      setRecord(response.data);
      const pdf = <DownloadablePDF certificate={response.data?.certificate} highlightedPage={response.data?.highlightedPage} yearBook={response.data?.yearBook} />
      setPdf(pdf);
      onOpen();

      setDownloadLoading(false);
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setDownloadLoading(false);
    }
  };

  useEffect(() => {
    getSearchHistory();
  }, []);
  return (
    <>
      <Modal onClose={onClose} size={"2xl"} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{record && record?.certificate && record?.certificate._doc && record?.certificate._doc.name ? record?.certificate?._doc?.name + " Full Report Preview" : "Full Report Preview"}</ModalHeader>
          <ModalCloseButton />
          <ModalBody minH={"700px"}>
            <Box
              style={{ width: "100%", height: "700px" }}
            >
              <PDFViewer width="100%" height="700px">
                {record && record?.certificate && <DownloadablePDF certificate={record?.certificate} highlightedPage={record?.highlightedPage} yearBook={record?.yearBook} />}
              </PDFViewer>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button variant="brand" isLoading={pdfDownloadLoading} loadingText="..Wait" onClick={pdfDownload}>Download</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Drawer isOpen={isHistoryDrawerOpen} placement="right" onClose={onHistoryDrawerClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color="#45005E"> Search History for {historyData && historyData.candidateName + " (" + historyData.resultCount + ")"} </DrawerHeader>
          <DrawerBody>
            {
              historyData &&
              <Accordion mt="4">
                {
                  historyData.data && historyData.data.length > 0 && historyData.data.map((record) => (
                    <AccordionItem p="2" bg="brand.100" borderWidth={0} borderRadius="15" mb="4">
                      <h2>
                        <AccordionButton>
                          <Box as='span' flex='1' textAlign='left' color="brand.500">
                            <Text fontSize={{ sm: "sm", md: "md" }} fontWeight="bold">{record.name}</Text>
                          </Box>
                          <AccordionIcon width="30px" height="30px" color="brand.500" />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4}>
                        <Card background="brand.100" width="100%">
                          <VStack columns={{ sm: 2, md: 4 }} spacing="4" width="100%">
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}><Icon as={FaUser} width="18px" height="18px" />&nbsp; Candidate</Tag> <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark">{record.name}</Text></Flex></Box>
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}><Icon as={FaUniversity} width="18px" height="18px" />&nbsp; Institution</Tag><Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark">{record?.institutionName}</Text></Flex></Box>
                            {/*<Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Faculty</Tag><Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark">{record?.faculty ? record?.faculty : "N/A"}</Text></Flex></Box>*/}
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Course</Tag> <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark">{record.course ? record.course : "N/A"}</Text></Flex></Box>
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Degree</Tag>  <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark"> {record.degree ? record.degree : "N/A"}</Text></Flex></Box>
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Grade</Tag>  <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark"> {record.grade ? record.grade : "N/A"}</Text></Flex></Box>
                            <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Year</Tag>  <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark"> {record.year ? record.year : "N/A"}</Text></Flex></Box>
                            {record.affliatedInstitution && <Box width="100%"><Flex gap={1}><Tag background="brand.500" color="white" fontWeight="bold" fontSize={{ sm: "sm", md: "md" }}>Year</Tag>  <Text fontWeight="bold" fontSize={{ sm: "sm", md: "md" }} color="dark"> {record.affliatedInstitution ? record.affliatedInstitution : "N/A"}</Text></Flex></Box>}
                          </VStack>
                          <Box mt="4" textAlign={"right"}>
                            <Button
                              variant="primary"
                              background="brand.500"
                              isLoading={downloadLoading}
                              loadingText="..Wait"
                              size="sm"
                              onClick={(e) => {
                                setCertificateId(record._id);
                                downloadCertificate(record._id);
                              }}>Download Full Report
                            </Button>
                          </Box>
                        </Card>
                      </AccordionPanel>
                    </AccordionItem>))}
              </Accordion>
            }
          </DrawerBody>
        </DrawerContent>
      </Drawer>

      <Box mt={{ sm: "40px", md: "80px" }} width="100%" mb="70">
        <Box width={{ sm: "100%", md: "50%" }} textAlign="right">
          <InputGroup>
            <TextFilter
              searchText={searchText}
              setSearchText={setSearchText}
              searchTextLabel="Search History by Candidate Name"
              handleSearch={handleSearch}
            />
            <InputRightElement pointerEvents='none'>
              <Icon as={FaSearch} mt="2" width='20px' height='20px' color='gray.300' />
            </InputRightElement>
          </InputGroup>
        </Box>
        <TableContainer>
          <Table variant='striped' colorScheme='brand'>
            <Thead>
              <Tr>
                <Th>Candidate Name</Th>
                <Th>Date</Th>
                <Th isNumeric>Result Found</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>

              {
                !loading && searches && searches.map((search) => (
                  <Tr>
                    <Td>{search.candidateName}</Td>
                    <Td>{formatTime(search.createdAt)}</Td>
                    <Td isNumeric>{search.resultCount}</Td>
                    <Td><Text textDecoration={"underline"}
                      onClick={() => {
                        setHistoryData(search);
                        onHistoryDrawerOpen();
                      }}
                    >View Results</Text></Td>
                  </Tr>
                ))
              }
              {loading && <LoadingHistory />}
            </Tbody>
          </Table>
        </TableContainer>

        {pageCount > 0 && (
          <Box width="100%" mt="5">
            <Paginate
              pageCount={pageCount}
              setFrom={setFrom}
              getRecords={getSearchHistory}
            />
          </Box>
        )}
      </Box>
    </>
  );
}

const LoadingHistory = () => {
  return (
    <>
      <Tr>
        <Td><SkeletonText noOfLines={1} /></Td>
        <Td><SkeletonText noOfLines={1} /></Td>
        <Td isNumeric><SkeletonText noOfLines={1} /></Td>
        <Td><SkeletonText noOfLines={1} /></Td>
      </Tr>
    </>
  );
};
