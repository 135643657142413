import axiosInstance from "./axiosInstance"; // Adjust the path accordingly
import axios from 'axios';
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const GetCertificate = async (certificateId, token) => {
  try {
    const response = await axiosInstance.get(SERVER_URL + "/certificate/" + certificateId, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetStat = async (filter, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/certificate/stat", filter, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const FetchCertificate = async (searchData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/certificate/fetch", searchData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const SendCertificate = async (sendData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/certificate/send", sendData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};


/*const GetCertificates = async (token) => {
  try {
    const response = await axiosInstance.get(
      SERVER_URL + "/certificate",
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
*/

const GetCertificates = async (pagination, filter, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL +
      "/certificate/getCertificates?from=" +
      pagination.from +
      "&limit=" +
      pagination.limit +
      "&orderBy=" +
      pagination.orderBy +
      "&sortOrder=" +
      pagination.sortOrder,
      filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetUploadBatches = async (pagination, filter, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL +
      "/certificate/getUploadBatches?from=" +
      pagination.from +
      "&limit=" +
      pagination.limit +
      "&orderBy=" +
      pagination.orderBy +
      "&sortOrder=" +
      pagination.sortOrder,
      filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const DownloadCertificate = async (certificateId, token) => {
  try {
    const response = await axios.get(
      SERVER_URL + "/certificate/download/" + certificateId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error downloading certificate:", error);
  }
}

const GetAvailableRecords = async () => {
  try {
    const response = await axios.get(
      SERVER_URL + "/certificate/getAvailableRecords",
      {
        headers: {
          Authorization: `Bearer `,
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error Getting Status:", error);
  }
}

const DeleteCertificate = async (certificateId, token) => {
  try {
    const response = await axiosInstance.delete(
      SERVER_URL + "/certificate/" + certificateId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const DeleteBatch = async (batchId, token) => {
  try {
    const response = await axiosInstance.delete(
      SERVER_URL + "/certificate/batch/" + batchId,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const AddCertificate = async (certificate, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/certificate", certificate, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UpdateCertificate = async (certificateData, certificateId, token) => {
  try {
    const response = await axiosInstance.patch(
      SERVER_URL + "/certificate/" + certificateId,
      certificateData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
        timeout: 1200000,
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const SearchCertificate = async (searchData, token) => {
  try {
    const response = await axiosInstance.post(SERVER_URL + "/certificate/search", searchData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UpdateCertificateByID = async (certificateData, certificateId, token) => {
  try {
    const response = await axiosInstance.patch(
      SERVER_URL + "/certificate/" + certificateId,
      certificateData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const UploadCertificate = async (certificateData, token) => {
  try {
    const formData = new FormData();
    formData.append("file", certificateData.file);
    formData.append("institution", certificateData.institution);
    formData.append("zip", certificateData.zip);
    formData.append("cover", certificateData.cover);
    formData.append("year", certificateData.year);

    const response = await axiosInstance.post(
      `${SERVER_URL}/certificate/uploadCertificates`,
      formData, // Pass formData directly here without `body:`
      {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          // Remove "Content-Type" header to let Axios set it correctly
        },
        mode: "cors",
        timeout: 1200000,
      }
    );

    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const GetSearchHistory = async (pagination, filter, token) => {
  try {
    const response = await axiosInstance.post(
      SERVER_URL +
      "/certificate/search/history?from=" +
      pagination.from +
      "&limit=" +
      pagination.limit +
      "&orderBy=" +
      pagination.orderBy +
      "&sortOrder=" +
      pagination.sortOrder,
      filter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        mode: "cors",
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

const SearchHistory = async (searchData, token) => {
  try {
    const response = await axios.post(SERVER_URL + "/certificate/searchhistory", searchData, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
    });
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export { GetAvailableRecords, GetStat, GetCertificate, SearchHistory, GetSearchHistory, GetUploadBatches, FetchCertificate, SendCertificate, GetCertificates, SearchCertificate, DownloadCertificate, DeleteCertificate, DeleteBatch, UpdateCertificateByID, UploadCertificate, AddCertificate, UpdateCertificate };

