import React from "react";
import "assets/css/App.css";
import { Routes, Route, BrowserRouter, HashRouter, Navigate } from "react-router-dom";
import AppLayout from "layouts/app";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/webauth";

import routesApp from "routesApp.js";
import routesAdmin from "routesAdmin.js";

import Login from "views/auth/login";
import Register from "views/auth/register";
import Verify from "views/auth/verify";
import VerifyEmail from "views/auth/verifyEmail";
import TwoFactor from "views/auth/twofactor";
import Forgot from "views/auth/forgot";
import UpdatePassword from "views/auth/updatepassword";
import Otp from "views/auth/otp";

function App() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if ((prop.layout === "/app") && !prop.collapse) {
        return (
          <Route
            path={prop.path}
            element={prop.component}
            key={key}
          />
        );
      }
      if ((prop.layout === "/app") && prop.collapse) {
        return (
          <Route
            path={prop.path + "/*"}
            element={prop.component}
            key={key}
          >
            <Route path="*" element={<Navigate to={prop.layout + "/" + prop.path + "/index"} replace />} />
            {getRoutes(prop.items)}
          </Route>
        );
      }

      if ((prop.layout === "/admin") && !prop.collapse) {
        return (
          <Route
            path={prop.path}
            element={prop.component}
            key={key}
          />
        );
      }
      if ((prop.layout === "/admin") && prop.collapse) {
        return (
          <Route
            path={prop.path + "/*"}
            element={prop.component}
            key={key}
          >
            <Route path="*" element={<Navigate to={prop.layout + "/" + prop.path + "/index"} replace />} />
            {getRoutes(prop.items)}
          </Route>
        );
      }
      if (prop.category) {
        return getRoutes(prop.items);
      } else {
        return null;
      }
    });
  };

  const AuthRoutes = () => {
    return (
      <Routes>
        <Route path="/" element={<Navigate to="/auth/login" replace />} />
        <Route path="/auth/*" element={<AuthLayout />}>
          <Route path="login" element={<Login />} />
          <Route path="forgot" element={<Forgot />} />
          <Route path="signup" element={<Register />} />
          <Route path="verify/:code" element={<Verify />} />
          <Route path="changepassword/:code" element={<UpdatePassword />} />
          <Route path="twofactor/:id" element={<TwoFactor />} />
          <Route path="verifyemail" element={<VerifyEmail />} />
          <Route path="otp" element={<Otp />} />
          <Route path="*" element={<Navigate to="/auth/login" replace />} />
        </Route>
      </Routes>
    );
  };

  const AppRoutes = () => {
    return (
      <Routes>
        <Route path="/app/*" element={<AppLayout />}>
          {getRoutes(routesApp)}
        </Route>
      </Routes>
    );
  };


  const AdminRoutes = () => {
    return (
      <Routes>
        <Route path="/admin/*" element={<AdminLayout />}>
          {getRoutes(routesAdmin)}
        </Route>
      </Routes>
    );
  };

  return (
    <BrowserRouter>
      <AuthRoutes />
      <AppRoutes />
      <AdminRoutes />
    </BrowserRouter>
  );
}

export default App;
