import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Flex,
  Box,
  Text,
  Tag,
  Button,
  Heading,
  Stack,
  SimpleGrid,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Skeleton,
  SkeletonText,
  useToast,
  useDisclosure
} from "@chakra-ui/react";
import { useNavigate, Link } from "react-router-dom";
import Paginate from "components/navigation/Paginate.js";
import TextFilter from "components/filter/TextFilter";
import Card from "components/card/Card.js";
import {
  GetAdminUsers,
  SearchUser,
  DeleteUser,
} from "services/userService";
import { checkprivileges } from "helpers/utils";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { FiArrowLeft, FiEye } from "react-icons/fi";
import { MdDelete, MdEditNote } from "react-icons/md";
import { FaPlus } from "react-icons/fa";

export default function Index() {
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isUserDrawerOpen, onOpen: onUserDrawerOpen, onClose: onUserDrawerClose } = useDisclosure();

  const userState = useSelector((state) => state?.user?.value);
  const token = userState.hasOwnProperty("token") ? userState.token : null;
  const privileges = userState.hasOwnProperty("privileges") ? userState.privileges : [];
  const [loading, setLoading] = useState(false);

  const [pageCount, setPageCount] = useState(0);
  const [sortOrder, setSortOrder] = useState("desc");
  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(200);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [user, setUser] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState("");

  const getUsers = async () => {
    try {
      setLoading(true);
      const pagination = {
        from: from,
        limit: limit,
        orderBy: orderBy,
        sortOrder: sortOrder,
      };
      const filter = {
        deleted: false
      };
      const response = await GetAdminUsers(pagination, filter, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setPageCount(response.data.count / limit);
        setUsers(response?.data?.users);
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const deleteUser = async () => {
    try {
      setLoading(true);
      const response = await DeleteUser(userId, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        toast({
          title: response.message || "User deleted successfully",
          status: "success",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        getUsers();
        onClose();
      }
    } catch (error) {
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    try {
      const searchData = {
        deleted: false,
        type: "admin",
        searchText
      };
      setLoading(true);
      const response = await SearchUser(searchData, token);
      setLoading(false);
      if (response.error || !response.success) {
        toast({
          title: response.error ? response.error : (response.message ? response.message : "An error occured"),
          status: "error",
          duration: 9000,
          position: "top-right",
          isClosable: true,
        });
        return;
      }
      if (response.success) {
        setUsers(response.data);
        return;
      }
    } catch (error) {
      setLoading(false);
      toast({
        title: error.message || "An error occured",
        status: "error",
        duration: 9000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const getPrivileges = (privileges) => {
    const privys = privileges && privileges.length > 0 ? privileges : [];
    return privys.map((priv, index) => {
      return (
        <Tag fontSize="13" key={index} background="brand.500" color="white" ml="1">
          {priv}
        </Tag>
      );
    });
  };

  useEffect(() => {
    !checkprivileges(privileges, ["Manage Users", "Admin"]) && (toast({ title: "You are not allowed", status: "error", duration: 9000, position: "top-right", isClosable: true, }) && navigate("/admin/dashboard"));
    getUsers();
  }, []);

  return (
    <>

      <Drawer isOpen={isUserDrawerOpen} placement="right" onClose={onUserDrawerClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color="#45005E">User Details</DrawerHeader>
          <DrawerBody>
            <Box>

              <Flex gap="2">
                <Tag fontSize={{ sm: "md", md: "lg" }} colorScheme="brand" background={"brand.500"} color="white" fontWeight="bold">First Name</Tag>
                <Text fontSize={{ sm: "md", md: "lg" }}>{user && user.firstName}</Text>
              </Flex>

              <Flex gap="2" mt="4">
                <Tag fontSize={{ sm: "md", md: "lg" }} colorScheme="brand" background={"brand.500"} color="white" fontWeight="bold">Last Name</Tag>
                <Text fontSize={{ sm: "md", md: "lg" }}>{user && user.lastName}</Text>
              </Flex>

              <Flex gap="2" mt="4">
                <Tag fontSize={{ sm: "md", md: "lg" }} colorScheme="brand" background={"brand.500"} color="white" fontWeight="bold">Email</Tag>
                <Text fontSize={{ sm: "md", md: "lg" }}>{user && user.phone}</Text>
              </Flex>

              <Flex gap="2" mt="4">
                <Tag fontSize={{ sm: "md", md: "lg" }} colorScheme="brand" background={"brand.500"} color="white" fontWeight="bold">Phone</Tag>
                <Text fontSize={{ sm: "md", md: "lg" }}>{user && user.email}</Text>
              </Flex>

              <Flex gap="2" mt="4">
                <Tag fontSize={{ sm: "md", md: "lg" }} width="140px" colorScheme="brand" background={"brand.500"} color="white" fontWeight="bold">Privileges</Tag>
                <Text fontSize={{ sm: "md", md: "lg" }}>{user?.role && user?.role?.privileges && getPrivileges(user?.role?.privileges)}</Text>
              </Flex>

            </Box>
          </DrawerBody>
        </DrawerContent>
      </Drawer>


      <Modal isOpen={isOpen} onClose={onClose} size={"md"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Icon
              as={FiArrowLeft}
              onClick={onClose}
              width="20px"
              height="20px"
            />
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid>
              <Box textAlign="center">
                <Heading
                  fontSize={{ base: "md", sm: "lg", md: "lg", lg: "xl" }}
                >
                  Are you sure you want to delete this?
                </Heading>
              </Box>
            </SimpleGrid>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={onClose}>
              No
            </Button>
            <Button
              variant="primary"
              background="#000000"
              isLoading={loading}
              loadingText="Please Wait"
              onClick={(e) => {
                e.preventDefault();
                deleteUser();
              }}
            >
              Proceed
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


      <Box mt={{ sm: "40px", md: "80px" }} width="100%" mb="70">
        <Text
          color={"black"}
          mb="4px"
          fontFamily={"ManropeSemiBold"}
          fontSize={{ sm: "md", md: "lg" }}> Admin Users
        </Text>
      </Box>


      <SimpleGrid
        columns={{ sm: 1, md: 2 }}
        w="100%"
        p="2"
        spacing="2"
        mb="4"
      >
        <Box mb="5">
          <Button
            variant="brand"
            width="120px"
            onClick={() => {
              navigate("/admin/users/adduser");
            }}
          >
            <Icon as={FaPlus} width="16px" height="16px" />&nbsp; Add Admin
          </Button>
        </Box>
        <Box width="100%" textAlign="right">
          <TextFilter
            searchText={searchText}
            setSearchText={setSearchText}
            searchTextLabel="Search By Name or Email"
            handleSearch={handleSearch}
          />
        </Box>
      </SimpleGrid>


      {loading ? (
        <LoadingUsers />
      ) : (
        <TableContainer>
          <Table variant="striped" fontSize={{ sm: "md", md: "lg" }}>
            <Thead>
              <Tr>
                <Th color="#000000">Name</Th>
                <Th color="#000000">Email</Th>
                <Th color="#000000">Privilege</Th>
                {privileges.includes("Admin") &&
                  <Th color="#000000">Action</Th>
                }
              </Tr>
            </Thead>
            <Tbody>
              {users !== null &&
                users.map((user) => (
                  <Tr>
                    <Td>{user.firstName} {user.lastName}</Td>
                    <Td>{user.email}</Td>
                    <Td>
                      {user?.role && user?.role?.privileges && getPrivileges(user?.role?.privileges)}
                    </Td>
                    {privileges.includes("Admin") &&
                      <Td>
                        {" "}
                        <Menu>
                          <MenuButton
                            as={Button}
                            variant="brand"
                            rightIcon={<ChevronDownIcon />}
                          >
                            Action
                          </MenuButton>
                          <MenuList>
                            <MenuItem
                              onClick={() => {
                                navigate("/admin/users/adduser/" + user._id, {
                                  state: { userId: user._id },
                                });
                              }}
                            >
                              <Icon
                                as={MdEditNote}
                                width="20px"
                                height="20px"
                                color="black"
                              />&nbsp; Edit &nbsp;{" "}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setUserId(user._id);
                                //setUser(user);
                                onOpen();
                              }}
                            >
                              <Icon
                                as={MdDelete}
                                width="20px"
                                height="20px"
                                color="black"
                              />&nbsp;Delete &nbsp;{" "}
                            </MenuItem>

                            <MenuItem
                              onClick={() => {
                                setUser(user);
                                onUserDrawerOpen();
                              }}>
                              <Icon
                                as={FiEye}
                                width="20px"
                                height="20px"
                                color="black"
                              />&nbsp; View &nbsp;{" "}
                            </MenuItem>

                          </MenuList>
                        </Menu>
                      </Td>
                    }
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
      {pageCount > 0 && (
        <Box width="100%" mt="5">
          <Paginate
            pageCount={pageCount}
            setFrom={setFrom}
            getRecords={getUsers}
          />
        </Box>
      )}
    </>
  );
};


const LoadingUsers = () => {
  return (
    <>
      <Card align="center" p="5px">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>

      <Card align="center" p="5px" mt="5">
        <Stack>
          <Skeleton height="5px" />
          <SkeletonText mt="2" noOfLines={3} spacing="3" />
          <Skeleton height="5px" />
        </Stack>
      </Card>
    </>
  );
};